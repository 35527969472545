import { addDoc, collection, where, query, getDocs } from "firebase/firestore";
import React, { useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../firebase";
import { toast } from "react-toastify";

export default function SignUp() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    liked: [],
  });

  const { firstName, lastName, email, phone, liked } = formData;

  function handleChange(e) {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const visitorRef = collection(db, "visitors");
    const q = query(visitorRef, where("email", "==", email));

    try {
      const querySnap = await getDocs(q);
      if (!querySnap.empty) {
        toast.error("Email already exists");
        return;
      }

      await addDoc(visitorRef, {
        firstName,
        lastName,
        email,
        phone,
        liked,
      });
      toast.success("Welcome to Listing Los Cabos");
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
      });

      sessionStorage.setItem("visitor", JSON.stringify(email));
      navigate("/listings");
    } catch (error) {
      toast.error("Enter a valid email");
    }
  }

  return (
    <div className="max-w-2xl mx-auto h-[88vh] px-6 md:px-0 font-montserrat flex items-center justify-center ">
      <div>
        <form onSubmit={handleSubmit}>
          <h1 className="font-palatino-lt-serif text-3xl md:text-5xl font-medium uppercase">
            Create Account
          </h1>

          <div className="flex flex-col md:flex-row md:space-x-4 space-y-3 md:space-y-0 mt-8 mb-3 md:mb-8">
            <div>
              <label className="text-sm mb-1" htmlFor="">
                First Name - <span className="text-gray-500">required</span>
              </label>
              <input
                onChange={handleChange}
                id="firstName"
                value={firstName}
                className="w-full py-2 border border-gray-300 focus:outline-none focus:border-cyan-600
                    pl-4 caret-gray-300"
                type="text"
              />
            </div>

            <div>
              <label className="text-sm mb-1" htmlFor="">
                Last Name - <span className="text-gray-500">required</span>
              </label>
              <input
                onChange={handleChange}
                id="lastName"
                value={lastName}
                className="w-full py-2 border border-gray-300 focus:outline-none focus:border-cyan-600
                    pl-4 caret-gray-300"
                type="text"
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row md:space-x-4 space-y-3 md:space-y-0 mb-3 md:mb-8">
            <div className="md:flex-1">
              <label className="text-sm mb-1" htmlFor="">
                Email Address - <span className="text-gray-500">required</span>
              </label>
              <input
                onChange={handleChange}
                id="email"
                value={email}
                className="w-full py-1.5 border border-gray-300 focus:outline-none focus:border-cyan-600
                    pl-4 caret-gray-300"
                type="text"
              />
            </div>

            <div className="md:flex-1">
              <label className="text-sm mb-1" htmlFor="">
                Phone - <span className="text-gray-500">required</span>
              </label>
              <input
                onChange={handleChange}
                id="phone"
                value={phone}
                className="w-full py-2 border border-gray-300 focus:outline-none focus:border-cyan-600
                    pl-4 caret-gray-300"
                type="text"
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row md:space-x-4 space-y-3 md:space-y-0 mt-6 mb-3 md:mb-8">
            <button
              type="submit"
              className="uppercase w-full py-3 text-white text-sm font-medium tracking-wider
                bg-[#18446b] hover:bg-opacity-70 duration-300"
            >
              Create account
            </button>

            <div className="relative w-full">
              <Link
                to={"/sign-in"}
                className="flex items-center justify-center uppercase w-full py-3 text-black text-sm font-medium tracking-wider
                    border border-transparent hover:border-[#18446b] duration-300"
              >
                Sign in
                <BsArrowRight className="ml-2" />
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
