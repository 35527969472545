import { useEffect, useRef, useState } from "react";

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

import './styles.css';

// import required modules
import { EffectFade, Navigation } from "swiper";

export const Carousel = ({ 
  openCarousel = false, 
  onClose = () => {}, 
  imgFiles = [], 
  videoFiles = [] 
}) => {
  const [modalOpen, setModalOpen] = useState(true);

  const trigger = useRef(null);
  const modal = useRef(null);

  const closeCarousel = () => {
    setModalOpen(false);
    onClose();
  };

  // open carousel from outside
  useEffect(() => {
    setModalOpen(openCarousel);
  }, [openCarousel]);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!modal.current) return;
      if (
        !modalOpen ||
        modal.current?.contains(target) ||
        trigger.current?.contains(target)
      )
        return;
      closeCarousel();
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!modalOpen || keyCode !== 27) return;
      closeCarousel();
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <div
        className={`fixed bg-white/70 left-0 top-0 flex h-full min-h-screen w-full items-center justify-center px-4 py-5 z-20 ${
          modalOpen ? "block" : "hidden"
        }`}
      >
        <div
          ref={modal}
          onFocus={() => setModalOpen(true)}
          onBlur={() => closeCarousel()}
          className="relative w-full max-w-[1070px] rounded-[20px] bg-primary px-8 py-12 text-center md:px-[70px] md:py-[50px]"
        >
          <Swiper
            style={{ zIndex: 100}}
            slidesPerView={1}
            modules={[EffectFade, Navigation]}
            navigation
            loop
          >
            {imgFiles.map((img, index) => {
              return (
                <SwiperSlide key={`image_${index}`}>
                  <img
                    src={img ? img?.savedUrl : "#"}
                    alt={img ? img?.name : "unamed"}
                    className="object-cover w-full h-full"
                  />
                </SwiperSlide>
              )
            })}
            {videoFiles.map((video, index) => {
              return (
                <SwiperSlide key={`video_${index}`}>
                  <video className="object-cover w-full h-full" controls>
                    <source 
                      src={video ? video?.savedUrl : "#"}
                      alt={video ? video?.name : "unamed"} />
                  </video>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </div>
  )
}