import { useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import axios from "axios";
import { toast } from "react-toastify";

export function useFetchServerListing({ filters = {} }){
  const auth = getAuth();
  const [ isLoading, setIsLoading ] = useState(true);
  const [ idToken, setIdToken ] = useState('');
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const filtersString = JSON.stringify(filters);

  useEffect(() => {
    setIsLoading(true);
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        setIdToken(await user.getIdToken());
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    try {
      idToken !== '' && fetchFilteredData();
    } catch (error) {
      console.error("Listing Server Error", error);
      setError(error);
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ idToken, filtersString ]);

  const fetchFilteredData = async () =>  {
    setIsLoading(true);
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/agent/listing`,
      {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      }
    );

    const { data } = response.data;
    console.log("response data: ", data);
    setData(data);
    setIsLoading(false);
  }

  const changeEnabledListing = async ({ id, enabled }) => {
    setIsLoading(true);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/agent/listing/${id}`,
        { enabled: !enabled },
        {
          headers: {
            'Authorization': `Bearer ${idToken}`
          }
        }
      );
      if(response.status === 200) {
        const updatedListings = data.listings.map(
          (listing) => listing.id === id ? 
            {...listing, enabled: !enabled} 
            : 
            listing
        );
        const activePosts = enabled ? data?.activePosts - 1 : data?.activePosts + 1;
        setData({
          ...data,
          activePosts,
          canCreateMore: activePosts < data?.maxActivePosts,
          listings: updatedListings
        });
      } else{
        toast.error("Try again in a few minutes");
      }
      setIsLoading(false);
    } catch(e) {
      console.error("Error on enable/disable listing: ", e);
      toast.error("Cannot enable/disabled Listing");
      setIsLoading(false);
    }
  }

  return { 
    fetchFilteredData, 
    changeEnabledListing,
    data,
    error, 
    isLoading 
  };

}