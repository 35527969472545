import './App.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { ToastContainer } from 'react-toastify';

import Navbar from './components/Navbar';
import Home from './pages/Home';
import Listings from './pages/Listings';
import Communities from './pages/Communities';
import About from './pages/About';
import Contact from './pages/Contact';
import SignIn from './pages/SignIn';
import ForgotPassword from './pages/ForgotPassword';
import SignUp from './pages/SignUp';
import Community from './pages/Community';
import RegisterAgent from './pages/RegisterAgent';
import LoginAgent from './pages/LoginAgent';
import ProfileAgent from './pages/ProfileAgent';
import PrivateRoute from './components/PrivateRoute';
import Admin from './pages/Admin';
import CreateListing from './pages/CreateListing';
import 'react-toastify/dist/ReactToastify.css';
import Listing from './pages/Listing';
import EditListing from './pages/EditListing';
import PropertiesMap from './components/PropertiesMap';
import Profile from './pages/Profile';
import Success from './pages/Success';
import Cancel from './pages/Cancel';
import SideBar from './components/SideBar';
import RootLayout from './components/RootLayout';
import Subscription from './components/Subscription';
import ListingsAgent from './pages/ListingsAgent';
import SendListings from './pages/SendListings';
import Messages from './pages/Messages';
import Footer from './components/Footer';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function App() {
  return (
    <Elements stripe={stripePromise}>
      <Router>
        <Navbar/>
        <RootLayout>
          <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/listings' element={<Listings/>}/>
            <Route path='/communities' element={<Communities/>}/>
            <Route path='/about' element={<About/>}/>
            <Route path='/contact' element={<Contact/>}/>
            <Route path='/sign-in' element={<SignIn/>}/>
            <Route path='/sign-up' element={<SignUp/>}/>
            <Route path='/forgot-password' element={<ForgotPassword/>}/>

            <Route path='/admin' element={<PrivateRoute/>}>
              <Route path='/admin' element={<Admin/>}/>
            </Route>

            <Route path='/profile-agent' element={<PrivateRoute/>}>
              <Route path='/profile-agent' element={<ProfileAgent/>}/>
            </Route>

            <Route path='/create-listing' element={<PrivateRoute/>}>
              <Route path='/create-listing' element={<CreateListing/>}/>
            </Route>

            <Route path='/edit-listing' element={<PrivateRoute/>}>
              <Route path='/edit-listing/:listingId' element={<EditListing/>}/>
            </Route>

            <Route path='/category/:categoryName/:listingId' element={<Listing/>} />

            <Route path='/community/:communityName' element={<Community/>}/>
            <Route path='/register-agent' element={<RegisterAgent/>}/>
            <Route path='/login-agent' element={<LoginAgent/>}/>

            <Route path='/fucking-map' element={<PropertiesMap />} />
            <Route path='/profile' element={<Profile/>} />

            <Route  path='/success' element={<Success/>} />
            <Route  path='/cancel' element={<Cancel/>} />
            
            <Route  path='/sidebar' element={<SideBar/>} />
            <Route  path='/subscription' element={<Subscription/>} />
            <Route  path='/listings-agent' element={<ListingsAgent/>} />
            <Route  path='/send-listings' element={<SendListings/>} />
            <Route  path='/messages' element={<Messages/>} />

          </Routes>
        </RootLayout>
        <Footer/>
      </Router>

      <ToastContainer 
        position='bottom-center'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='dark' />
    </Elements>
  )
}

export default App;
