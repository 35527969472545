import React from "react";
import failure from "../assets/failure.jpg";
import { useNavigate } from "react-router-dom";

export default function Cancel() {
  const navigate = useNavigate();

  return (
    <div className="m-0 p-0">
      <div className="w-full min-h-[80vh] flex flex-col items-center justify-center">
        <div className="flex flex-col items-center justify-center mx-auto my-10">
          <img src={failure} alt="failure" width={220} height={220} />
          <h3 className="text-4xl font-bold text-slate-700 pt-20 md:pt-0 tracking-wider">
            Ha ocurrido un error
          </h3>
          <button
            onClick={() => navigate("/")}
            className="w-40 text-white uppercase text-xl my-16 px-2 py-2 bg-sky-500 rounded"
          >
            Home
          </button>
        </div>
      </div>
    </div>
  );
}
