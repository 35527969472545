import React, { useState } from 'react';
import { useMemo, useEffect } from 'react';
import { GoogleMap, useLoadScript, MarkerF, InfoWindowF } from '@react-google-maps/api';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

export const buildMarkerFromListing = ({ id, data }) => {
  const geolocation = data?.location?.geolocation;
  if (geolocation) {
    const { lat, lng } = geolocation;
    const address = data?.location?.fullAddress;
    const imgUrls = data?.imgFiles.map(file => file?.savedUrl) ?? [];
    const regularPrice = data.regularPrice;
    return { lat, lng, address, imgUrls, regularPrice, id };
  }
  return {};
}

export default function PropertiesMap({ onMarkerClick }) {
    const [markers, setMarkers] = useState([]);

    // Fetch markers from Firestore and update the state
    useEffect(() => {
        const fetchMarkers = async () => {
            try {
                const listingsRef = collection(db, 'listings');
                const querySnap = await getDocs(listingsRef);
                const markersData = [];

                querySnap.forEach((doc) => {
                    const data = doc.data();
                    markersData.push(buildMarkerFromListing({ id: doc.id, data }));
                })
                setMarkers(markersData);
            } catch (error) {
                console.error('Error fetching markers');
            }
        }
        fetchMarkers()
    }, []);

    return <Map markers={markers} onMarkerClick={onMarkerClick} />;


}

export const Map = ({ markers, onMarkerClick, centerMap = { lat: 22.9336877, lng: -109.971412 }}) => {
  const center = useMemo(() => (centerMap), []);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [hoveredMarker, setHoveredMarker] = useState(null);
  const [closeInfoWindow, setCloseInfoWindow] = useState(true);


  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY
  });

  // Delay before closing the InfoWindow
  useEffect(() => {
      if (!hoveredMarker && !selectedMarker) {
          const timeout = setTimeout(() => {
              setCloseInfoWindow(true);
          }, 500)
          return () => clearTimeout(timeout);
      }
  }, [hoveredMarker, selectedMarker]);

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <GoogleMap
      options={{ scrollwheel: false }}
      zoom={12}
      center={center}
      mapContainerStyle={{ height: '100vh' }}>
      {markers.map((marker, index) => (
        <MarkerF
          onClick={() => onMarkerClick(marker.id)}
          // icon='/images/spinner.svg'
          onMouseOver={() => {
              setSelectedMarker(marker)
              setHoveredMarker(marker)
              setCloseInfoWindow(false) // Prevent InfoWindow from closing on hover
          }}
          onMouseOut={() => {
              if (closeInfoWindow) {
                  setSelectedMarker(null)
                  setHoveredMarker(null)
              }
          }}
          key={index}
          position={{ lat: marker.lat, lng: marker.lng }}>

          {selectedMarker === marker && (
            <InfoWindowF
              position={{ lat: marker.lat, lng: marker.lng }}
              onCloseClick={() => setSelectedMarker(null)}
              onMouseOut={() => {
                  setHoveredMarker(null)
                  setHoveredMarker(null)
              }}>
              <div
                onClick={() => onMarkerClick(marker.id)}
                className='cursor-pointer h-full flex flex-col items-start justify-center overflow-hidden p-2 space-y-1'>
                  {selectedMarker.imgUrls && selectedMarker.imgUrls.length > 0 && (
                      <img
                          className='h-40 '
                          src={selectedMarker.imgUrls[0]}
                          alt={selectedMarker.address} />
                  )}
                <p className='font-medium text-sm text-gray-500'>{selectedMarker.address}</p>
                <p className='font-semibold text-sm text-blue-400'>${selectedMarker.regularPrice}</p>
              </div>
            </InfoWindowF>
          )}
        </MarkerF>
      ))}
    </GoogleMap>
  )
};