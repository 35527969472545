import { useEffect, useState } from "react";
import { FEATURES_AND_AMENITIES } from "../../shared/constants";

const _ = require("lodash");

export const FeaturesAndAmenitiesSection = ({ data = {}, cols = 4 }) => {
  const [ rows, setRows ] = useState([]);

  useEffect(() => {
    if(data && Object.keys(data).length > 0) {
      const features = FEATURES_AND_AMENITIES
        .map(feature => {
          return { ...feature, show: !!data[feature.firebaseKey] };
        })
        .filter(feature => feature.show)
        .map(feature => feature.label);
      setRows(_.chunk(features, cols));
    }
  }, [data, cols]);

  return <>
    {
      rows?.map((row, index) => 
        <div key={index} className="flex flex-wrap justify-center gap-2">
          {row.map((label) => (
            <span key={label} className="text-dark text-md font-medium dark:text-dark-4 dark:border-dark-4 border-dark m-2 inline-block rounded border py-2 px-2.5">
              { label }
          </span>
          ))}
        </div>
      )
    }
    </>
  
};