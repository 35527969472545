import React from 'react'

export default function SocialIcons({ Icons }) {
  return (
    <div className=''>
      {Icons.map(icon => (
        <a 
        key={icon.name}
        href={icon.link}
        target='_blank'
        rel='noopener noreferrer'
        className='p-2 cursor-pointer inline-flex itens-center duration-300 ease-in-out'>
            {/* <ion-icon name={icon.name}></ion-icon> */}
            <img 
            src={icon.src} 
            alt="" 
            className='h-[5vh]'
            onMouseOver={(e) => e.currentTarget.src = icon.hover}
            onMouseOut={(e) => e.currentTarget.src = icon.src} />
        </a>
      ))}
    </div>
  )
}
