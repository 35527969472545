import React from "react";
import SideBar from "./SideBar";
import { useLocation } from "react-router-dom";
import { AgentContextProvider } from "../context/AgentContext";

export default function RootLayout({ children }) {
  const location = useLocation();

  const shouldShowSidebar =
    location.pathname === "/profile-agent" ||
    location.pathname === "/subscription" ||
    location.pathname === "/listings-agent" ||
    location.pathname === "/send-listings" ||
    location.pathname === "/messages";

  return (
    <AgentContextProvider>
      <div className="flex gap-0 md:gap-5 overflow-x-hidden ">
        {shouldShowSidebar && (
          <div className="w-1/8 flex-shrink-0">
            <div className="h-screen">
              <SideBar />
            </div>
          </div>
        )}
        {children}
      </div>
    </AgentContextProvider>
  );
}
