import { useState } from "react";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { db } from "../firebase";
import axios from "axios";

export default function useAgent() {
  const [isLoading, setIsLoading] = useState(false);

  const authAgent = async (data) => {
    try {
      setIsLoading(true);
      const { firstName, email, password } = data;

      const auth = getAuth();
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      updateProfile(auth.currentUser, {
        displayName: firstName,
      });

      const user = userCredential.user;
      // define user's role
      const userRole = "guest";

      delete data.password;

      // add time stamp
      data.timeStamp = serverTimestamp();

      // create collection and navigate to home also create the user document with role in Firestore
      const userDocRef = doc(db, "users", user.uid);
      await setDoc(userDocRef, {
        ...data,
        role: userRole, // Add the role field with 'guest value here
      });

      // get token to authorize the request
      const idToken =  await user.getIdToken();

      // Send welcome email to the newly registered user
      if (email && idToken) {
        await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/email/send-register-emails?email=${email}`,
          {
            headers: {
              'Authorization': `Bearer ${idToken}`
            }
          }
        );
      }
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return { authAgent, isLoading };
}
