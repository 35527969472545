import { Select } from "./Select";

export const FeatureAndAmenitiesListingComponent = ({
  onChange,
  swimmingPool,
  garden,
  greenAreas,
  terraces,
  lobby,
  surveillance,
  internetAccess,
  gym,
  serviceRoom,
  multipurposeRoom,
}) => {
  return (
    <>
      <h1 className="text-2xl text-center mt-6 mb-6 font-semibold">
        Creating Listing
      </h1>

      <div className="flex gap-3">
        <Select
          title="Swimming Pool"
          id="swimmingPool"
          onChange={onChange}
          value={swimmingPool}
          options={[
            { id: false, value: "No" },
            { id: true, value: "Yes" },
          ]}
        />

        <Select
          title="Garden"
          id="garden"
          onChange={onChange}
          value={garden}
          options={[
            { id: false, value: "No" },
            { id: true, value: "Yes" },
          ]}
        />
      </div>
      <div className="flex gap-3">
        <Select
          title="Green Areas"
          id="greenAreas"
          onChange={onChange}
          value={greenAreas}
          options={[
            { id: false, value: "No" },
            { id: true, value: "Yes" },
          ]}
        />

        <Select
          title="Terraces"
          id="terraces"
          onChange={onChange}
          value={terraces}
          options={[
            { id: false, value: "No" },
            { id: true, value: "Yes" },
          ]}
        />
      </div>
      <div className="flex gap-3">
        <Select
          title="Lobby"
          id="lobby"
          onChange={onChange}
          value={lobby}
          options={[
            { id: false, value: "No" },
            { id: true, value: "Yes" },
          ]}
        />

        <Select
          title="Surveillance"
          id="surveillance"
          onChange={onChange}
          value={surveillance}
          options={[
            { id: false, value: "No" },
            { id: true, value: "Yes" },
          ]}
        />
      </div>
      <div className="flex gap-3">
        <Select
          title="Internet access"
          id="internetAccess"
          onChange={onChange}
          value={internetAccess}
          options={[
            { id: false, value: "No" },
            { id: true, value: "Yes" },
          ]}
        />

        <Select
          title="Gym"
          id="gym"
          onChange={onChange}
          value={gym}
          options={[
            { id: false, value: "No" },
            { id: true, value: "Yes" },
          ]}
        />
      </div>
      <div className="flex gap-3">
        <Select
          title="Service room"
          id="serviceRoom"
          onChange={onChange}
          value={serviceRoom}
          options={[
            { id: false, value: "No" },
            { id: true, value: "Yes" },
          ]}
        />

        <Select
          title="Multipurpose room"
          id="multipurposeRoom"
          onChange={onChange}
          value={multipurposeRoom}
          options={[
            { id: false, value: "No" },
            { id: true, value: "Yes" },
          ]}
        />
      </div>
    </>
  );
};
