import React from "react";
import image5 from "../assets/5.jpg";

export default function About() {
  return (
    <div
      className="w-full  min-h-[60vh] "
      style={{
        background: `url(${image5}) no-repeat center`,
        backgroundSize: "cover",
      }}
    >
      <div
        className="w-full  min-h-[60vh] px-10"
        style={{ backgroundColor: "rgba(0, 15, 45, 0.3)" }}
      >
        <div className="w-full pt-16 pb-16">
          <div className="grid md:grid-cols-2 grid-cols-1 gap-20 max-w-[1200px] mx-auto">
            <h1 className="text-6xl text-center text-white font-semibold">
              About
            </h1>
            <div></div>
          </div>
        </div>

        <div className="grid md:grid-cols-2 grid-cols-1 gap-20 max-w-[1200px] mx-auto">
          <div>
            <h2 className="text-2xl text-white text-center font-semibold pb-2">
              Los Cabos MLS Listing
            </h2>
            <p className="text-lg text-white text-justify leading-8">
              Web platform that allows information on exclusive properties to be
              shared, allowing collaboration between professionals in the real
              estate sector, collaborating with the purchase, sale and promotion
              of properties.
            </p>
          </div>
          <div>
            <h2 className="text-2xl text-white text-center font-semibold pb-2">
              What is Listing Los Cabos?
            </h2>
            <p className="text-lg text-white text-justify leading-8">
              We are a multiple listing web platform is Cabos Baja California
              Sur. Listing Los Cabos was born from the need to have
              technological and professional tools for brokers, agents as well
              as construction companies and real estate developers. Without a
              doubt Listing Los Cabos will be a great support tool.
            </p>
          </div>
        </div>
        <div>
          <p className="pt-10 text-lg text-white text-center">
            Information and membersips by <strong>whatsapp 5511077339</strong>
          </p>
        </div>
      </div>
    </div>
  );
}
