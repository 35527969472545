import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';

import './styles.css';

export default function CommunityItem({ img, caption, link, isFeatured = true }) {
  const isDesktop = useMediaQuery({ minWidth: 993 })
  return (
    <div className="relative group h-96 w-full background-red">
      {isFeatured && 
        <div className="absolute left-2 top-0 z-10 w-24">
          <img className="object-fill" src="/images/iconos/FeaturedProperty.png" alt="featured" />
        </div>
      }
      <div className="absolute left-0 top-2 h-96 w-full">
        <Link to={link}>
          <img
            className='h-full w-full  object-fill'
            src={img}
            alt={caption}
          />
          <div className='absolute top-0 h-full w-full bg-gradient-to-t from-black to-transparent opacity-30 hover:opacity-100 transition-opacity duration-300'
              style={{
                backgroundImage: 'linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))',
              }} /> 
          <p className='absolute bottom-0 text-3xl font-palatino-lt-serif tracking-wider uppercase text-white pl-4 pb-8 z-40'>
            {caption}
          </p>
        </Link>
      </div>
    </div>
  )
}
