import React from 'react'
import useAuthStatus from '../hooks/useAuthStatus'
import { Navigate, Outlet } from 'react-router-dom'
import Spinner from './Spinner'

export default function PrivateRoute({ adminRequired = false }) {
    const { loggedIn, isAdmin, checkStatus } = useAuthStatus()

    if(checkStatus) {
        return <Spinner/>
    }

    if(loggedIn) {
        if(adminRequired && isAdmin) {
            return <Outlet/>
        } else if (!adminRequired) {
            return <Outlet/>
        }
    }

  return <Navigate to={'/login-agent'}/>
}
