import { getAuth } from "firebase/auth";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ListingItem from "../components/ListingItem";
import {
  deleteDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import { toast } from "react-toastify";
import { AgentContext } from "../context/AgentContext";
import Spinner from "../components/Spinner";

export default function ListingsAgent() {
  const navigate = useNavigate();
  const [listings, setListings] = useState(null);

  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [planType, setPlanType] = useState("");
  const auth = getAuth();

  const { 
    data: { listings: dataListings, canCreateMore  }, 
    isLoading, 
    fetchFilteredData, 
    changeEnabledListing 
  } = useContext(AgentContext);

  // DISPLAY NAME AND PLAN TYPE
  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        setUserId(user.uid);
        setUserName(user.displayName);
        // Retrieve user data from Firestore
        const userRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists) {
          const userData = docSnap.data();
          if (userData.subscription) {
            setPlanType(userData.subscription.planType || "");
          }
        }
      } else {
        setUserId("");
        setUserName("");
      }
    });
  }, [ userId, auth ]);

  useEffect(() => {
    setListings(dataListings);
  }, [ dataListings ]);

  // LOGOUT
  const handleLogout = () => {
    auth.signOut();
    navigate("/");
  };

  // Delete Listing
  const onDelete = async (listingId) => {
    if (window.confirm("Are you sure you want to delete?")) {
      await deleteDoc(doc(db, "listings", listingId));
      toast.success("Successfully deleted the listing");
      // update agent context
      await fetchFilteredData();
    }
  }

  // Edit Listings
  const onEdit = (listingId) => {
    navigate(`/edit-listing/${listingId}`);
  }

  // when switch enabled button
  const handleOnChangeListingEnabled = (changedListing) => {
    console.log(changedListing);
    changeEnabledListing({ id: changedListing.id, enabled: changedListing.enabled});
  };

  return (
    <section className="max-w-6xl mx-auto flex flex-col justify-center items-center">
      <div
        className="p-4 text-lg cursor-pointer text-blue-400 hover:text-blue-500"
        onClick={handleLogout}
      >
        Logout
      </div>

      <div className="flex space-x-2">
        {userId ? (
          <p className="text-base font-semibold text-center mb-2">{userName}</p>
        ) : (
          ""
        )}
        {planType ? <p className="uppercase font-semibold">{planType}</p> : ""}
      </div>

      <div className="flex flex-col items-center justify-center mb-2">
        <button
          disabled={!canCreateMore}
          onClick={() => canCreateMore? navigate("/create-listing") : null}
          className="bg-[#18446b] hover:bg-opacity-70 px-44 py-3 text-white text-lg tracking-widest font-medium disabled:bg-slate-200 disabled:text-slate-400"
        >
          New Listing
        </button>
      </div>

      <div className="mx-auto px-3 mt-6">
        {isLoading && <Spinner />}
        {listings?.length > 0 && (
          <>
            <h2 className="text-2xl text-center font-semibold gap-4 mb-6 ">
              My Listings
            </h2>
            <ul className="-mx-4 flex flex-wrap">
              {listings.map((listing) => (
                <ListingItem
                  key={listing.id}
                  id={listing.id}
                  listing={listing}
                  onDelete={() => onDelete(listing.id)}
                  onEdit={() => onEdit(listing.id)}
                  showEnabled={true}
                  onChangeEnabled={handleOnChangeListingEnabled}
                />
              ))}
            </ul>
          </>
        )}
      </div>
    </section>
  );
}
