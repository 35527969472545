import { createContext, useEffect, useMemo, useState } from "react";
import { useFetchServerListing } from "../hooks/useFetchServerListing";
import { SUBSCRIPTION_PLANS } from "../shared/constants";

export const AgentContext = createContext(null);

export const AgentContextProvider = ({ children }) => {

  const { data, isLoading, fetchFilteredData, changeEnabledListing } = useFetchServerListing({ filters: {} });
  const [ userSubscription, setUserSubscription ] = useState({});

  useEffect(() => {
    if(data.stripePrice) {
      const plan = SUBSCRIPTION_PLANS.find((plan) => plan.stripePrice === data.stripePrice);
      console.log("[DEBUG] User Subscription Plan: ", plan);
      setUserSubscription(plan);
    } else{
      setUserSubscription(null);
    }
  }, [ data.stripePrice ]);

  const contextValue = useMemo(() => ({
    data,
    isLoading,
    userSubscription,
    fetchFilteredData,
    changeEnabledListing
  }), [data, isLoading, userSubscription]);

  return <AgentContext.Provider value={contextValue}>
    { children }
  </AgentContext.Provider>

}