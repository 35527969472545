import { useEffect, useState } from "react";
import { FACILITY_TYPES } from "../shared/constants";
import { InputNumber } from "./InputNumber";
import { Select } from "./Select";
import { TypeOperation } from "./TypeOperation";

export const CreateListingComponent = ({
  onChange,
  title,
  type,
  property,
  regularPrice,
  totalArea,
  usefulArea,
  bedrooms,
  bathrooms,
  garage,
  antiquity,
  community,
  options = {
    communities: [] = []
  }
}) => {

  const [communityOptions, setCommunityOptions] = useState([]);

  useEffect(() => {
    setCommunityOptions([
      {
        id: "",
        data: {
          title: ""
        }
      },
      ...options?.communities
    ]);
  }, [options?.communities]);

  const getOptions = ({ customLabel }) => (
    [
      { id: "0", value: 0 },
      { id: "1", value: 1 },
      { id: "2", value: 2 },
      { id: "3", value: 3 },
      { id: "4", value: 4 },
      { id: "5", value: 5 },
      { id: "+5", value: 6, customLabel },
    ]
  );

  return (
    <>
      <h1 className="text-2xl text-center mt-6 mb-6 font-semibold">
        {title ? title : "Creating Listing"}
      </h1>

      <TypeOperation type={type} onClick={onChange} />
      <Select
        onChange={onChange}
        id="property"
        value={property}
        title="Type of property"
        options={
          FACILITY_TYPES.map((type) => ({
            id: type.key,
            value: type.value
          }))
        }
      />

      <Select
        onChange={onChange}
        id="community"
        value={community}
        title="Community"
        options={
          communityOptions.map((com) => ({
            id: com.id,
            value: com.data.title
          }))
        }
      />

      <InputNumber
        id="regularPrice"
        value={regularPrice}
        onChange={onChange}
        title="Price USD"
      />

      <div className="flex gap-3">
        <InputNumber
          id="totalArea"
          value={totalArea}
          onChange={onChange}
          title="sqft full"
        />

        <InputNumber
          id="usefulArea"
          value={usefulArea}
          onChange={onChange}
          title="sqft built"
        />
      </div>

      <div className="flex gap-3">
        <Select
          title="Bedrooms"
          id="bedrooms"
          value={bedrooms}
          onChange={onChange}
          options={getOptions({ customLabel: "+5" })}
        />

        <Select
          title="Bathrooms"
          id="bathrooms"
          value={bathrooms}
          onChange={onChange}
          options={getOptions({ customLabel: "+5" })}
        />
      </div>
      <div className="flex gap-3">
        <Select
          title="Garage"
          id="garage"
          value={garage}
          onChange={onChange}
          options={getOptions({ customLabel: "+5" })}
        />

        <Select
          title="Antiquity"
          id="antiquity"
          value={antiquity}
          onChange={onChange}
          options={getOptions({ customLabel: "+5 years" })}
        />
      </div>
    </>
  );
};
