import React from 'react';
import { useMediaQuery } from 'react-responsive'
import CommunityItem from '../components/CommunityItem';
import ProgressBar from '../components/ProgressBar';
import { useFetchCommunities } from '../hooks/useFetchCommunities';

export default function Communities() {
  const isDesktop = useMediaQuery({ minWidth: 993 });
  const { data: communities, loading: loadingCommunities } = useFetchCommunities({});

  return (
    <main className="flex-1 min-w-0 font-montserrat px-3 md:px-20 py-0">
      <h1 className='text-5xl font-beathani-serif uppercase tracking-wider py-12 md:py-15'>Communities</h1>
      { loadingCommunities && <div className='mt-2'><ProgressBar /></div> }
      <div className={`${isDesktop ? 'sm:grid grid-cols-2 md:grid-cols-3 gap-4 pb-32' : 'flex flex-col space-y-6 pb-32'}`}>
        { !loadingCommunities && communities.map(({ id, data: { slug, imgCoverUrl: img, overview, isFeatured }}) => (
          <CommunityItem
            key={id}
            link={`/community/${slug}`}
            img={img}
            caption={overview?.title}
            isFeatured={isFeatured} />
        ))}
      </div>
    </main>
    
  )
}
