import { useDropzone } from "react-dropzone";
import { useCallback } from "react";

export const FileListingComponent = ({ onChange, onDeleteFile, imgFiles, videoFiles }) => {
  const onDrop = useCallback((acceptedFiles) => {
    const filesToSave = acceptedFiles.map(acceptedFile => ({
      type: acceptedFile.type,
      path: acceptedFile.path,
      name: acceptedFile.name,
      file: acceptedFile
    }))
    onChange(filesToSave);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const images = !imgFiles ? 
    [] 
    : 
    imgFiles.map((file, index) => (
      <li
        key={file.path}
        className="rounded bg-black/10 max-w-fit px-3 flex justify-center items-center text-[10px] leading-4 min-w-[80px] flex justify-between items-center mb-2"
      >
        {file.name}
        <img
          src="/images/close.svg"
          alt="Close"
          className="cursor-pointer pl-2"
          onClick={() => {
            onDeleteFile(file.type, index);
          }}
        />
      </li>
    ));

    const videos = !videoFiles ? 
    [] 
    : 
    videoFiles.map((file, index) => (
      <li
        key={file.path}
        className="rounded bg-black/10 max-w-fit px-3 flex justify-center items-center text-[10px] leading-4 min-w-[80px] flex justify-between items-center mb-2"
      >
        {file.name}
        <img
          src="/images/close.svg"
          alt="Close"
          className="cursor-pointer pl-2"
          onClick={() => {
            console.log("onDelte -> Filelisting: ",file.type, index);
            onDeleteFile(file.type, index);
          }}
        />
      </li>
    ));

  return (
    <>
      <h1 className="text-2xl text-center mt-6 mb-6 font-semibold">
        Image Listing
      </h1>

      <section>
        <div
          {...getRootProps({
            className: "dropzone",
          })}
          className="border-[1px] border-dashed border-grey-500 mb-2 flex flex-col items-center justify-center h-[112px] w-full"
        >
          <input {...getInputProps()} />
          <img src="/images/attach.svg" alt="Attach" width="17" height="30" />
          <p className="font-normal text-black text-sm">
            {"Arrastra la imagen o adjuntala aqui"}
          </p>
        </div>
        <aside>
          <ul>{images}</ul>
          <ul>{videos}</ul>
        </aside>
      </section>
    </>
  );
};
