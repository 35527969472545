import React from "react";
import Spinner from "./Spinner";
import { SUBSCRIPTION_PLANS } from "../shared/constants";
import useSubscription from "../hooks/useSubscription";

export default function Subscription() {
  const { 
    isLoading, 
    subscription, 
    startCheckout, 
    cancelSubscription 
  } = useSubscription();

  return (
    <main className="grid grid-cols-1 lg:grid-cols-3 my-10 mr-5 sm:mx-5 xl:mx-20 gap-5 z-10 lg:z-50 place-items-center w-full h-full">
      { isLoading && <Spinner /> }
      { SUBSCRIPTION_PLANS.map((item, index) => (
        <div
          className={`bg-white px-6 py-8 shadow-md rounded-xl w-full mx-auto grid place-items-center ${
            subscription?.plan?.id === item.stripePrice &&
            "border-[14px] border-green-400"
          }`}
          key={index}
        >
          <img
            src={item.src}
            alt={item.title}
            width={200}
            height={200}
            className="h-40"
          />
          <p className="font-bold text-4xl text-center py-4 text-slate-700">
            {item.title}
          </p>
          <p className="text-xs text-slate-500 px-6 text-justify">
            {item.description}
          </p>
          <p className="text-4xl text-center font-bold py-4 text-sky-500 ">
            ${item.price}
          </p>
          {subscription?.plan?.id === item.stripePrice ? (
            <>
              <button
                onClick={() => cancelSubscription(subscription?.id)}
                className={`bg-red-600 text-white text-base uppercase rounded-md w-32 py-2 font-bold ${
                  subscription ? "" : "cursor-pointer"
                }`}
              >
                Cancel
              </button>
            </>
          ) : (
            <button
              disabled={!!subscription}
              onClick={() => startCheckout(item.stripePrice)}
              className={` text-white text-base uppercase rounded-md w-28 py-2 font-bold ${
                !!subscription ? "bg-gray-400" : "bg-sky-500"
              } `}
            >
              Start
            </button>
          )}
        </div>
      ))}
    </main>
  );
}
