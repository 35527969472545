export const Textarea = ({ onChange, title, id, value }) => {
  return (
    <div className="w-full">
      {title && <p className="text-md mt-6">{title}</p>}

      <div>
        <textarea
          rows={10}
          id={id}
          className="border-gray-300 focus:border-[#18446b] w-full
          transition duration-150 ease-in-out"
          onChange={onChange}
          value={value}
        />
      </div>
    </div>
  );
};
