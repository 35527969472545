import { useState, useEffect } from "react";
import { collection, getDocs, query, where } from 'firebase/firestore'
import { db } from '../firebase';
import { COMMUNITY_COLLECTION } from "../shared/firebaseCollections";

export function useFetchCommunity({ communitySlug }) {

   const [data, setData] = useState({});
   const [error, setError] = useState(null);
   const [loading, setLoading] = useState(true);

   useEffect(() => {
      fetchFilteredData();
   }, [ communitySlug ]);

   const fetchFilteredData = async () => {
      try {
         setLoading(true);
         let q = collection(db, COMMUNITY_COLLECTION);
         q = query(q, where('slug', '==', communitySlug));
         const querySnap = await getDocs(q);
         if(!querySnap.empty){
            const doc = querySnap.docs[0];
            const community = {
               id: doc.id,
               data: doc.data(),
            };
            setData(community);
            setLoading(false);
         }
      } catch (error) {
        console.log(error);
        setError(error);
      } finally{
         setLoading(false);
      }
   };

   return { fetchFilteredData, data, loading, error };

}