export const FlexInputText = ({ 
  id, 
  label, 
  placeholder, 
  required = false, 
  type = "text", 
  rows = "1",
  value, 
  onChange,
  rootClass = "",
  register,
  error = null
}) => {

  const handleChange = (e) => {
    if (required)
    onChange(e);
  }
  const renderInput = () => {
    switch(type) {
      case 'email':
      case 'text':
        return <input
          id={id}
          {...register(id, { required: required })}
          required={required}
          type={type}
          placeholder={placeholder}
          className={`w-full rounded-lg border ${error? "border-red-500 focus:border-red-700 placeholder-red-500" : "text-dark focus:border-primary dark:focus:border-primary placeholder-dark-5 dark:text-white dark:border-dark-3"}  bg-transparent px-5 py-3 outline-none duration-200`}
          value={value}
          onChange={handleChange}
        />
      case 'textarea':
        return <textarea
          id={id}
          required={required}
          {...register(id, { required: required })}
          type={"text"}
          rows={rows}
          placeholder={placeholder}
          className={`w-full rounded-lg border ${error? "border-red-500 focus:border-red-700 placeholder-red-500" : "text-dark focus:border-primary dark:focus:border-primary placeholder-dark-5 dark:text-white dark:border-dark-3"} bg-transparent px-5 py-3 outline-none duration-200`}
          value={value}
          onChange={handleChange}
        />
    }
  }

  return (
    <div className={`w-full px-4 ${rootClass}`}>
      <div className="mb-6">
        <label
          htmlFor={id}
          className={`mb-2.5 block text-base font-medium ${error? "text-red-500" : "text-dark dark:text-white"}`}
        >
          {label}
        </label>
        { renderInput() }
      </div>
    </div>
  );
}