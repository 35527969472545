import { useState } from "react";
import { getAuth } from "firebase/auth";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { db } from "../firebase";
import { USERS_COLLECTION } from "../shared/firebaseCollections";

export default function useProfileAgent() {
  const [isLoading, setIsLoading] = useState(false);

  const updateProfileAgent = async (data) => {
    try {
      setIsLoading(true);

      const auth = getAuth();

      // add time stamp
      data.updatedTimestamp = serverTimestamp();

      // create collection and navigate to home also create the user document with role in Firestore
      const userDocRef = doc(db, USERS_COLLECTION, auth.currentUser.uid);
      await setDoc(userDocRef, data);

    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return { updateProfileAgent, isLoading };
}
