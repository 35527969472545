import React, { useState } from 'react';

const ButtonGroup = ({ buttons = [], onClickButton = () => {} }) => {
  const [ selectedButton, setSelectedutton ] = useState(null);
  
  const handleSelectButton = (button) => {
    setSelectedutton(button);
    onClickButton(button);
  };

  return (
    <div className="inline-flex">
      {buttons.map((button, index) => 
        <span key={index} 
              href="#" 
              onClick={() => handleSelectButton(button)}
              className={`${selectedButton?.key === button.key? "bg-[#18446b] text-white":""} bg-primary hover:border-primary hover:bg-primary hover:text-neutral-400 inline-flex items-center justify-center border py-[11px] px-[12px] text-center text-base transition-all sm:px-6 cursor-pointer`}>
          {button.value}
        </span>
      )}
    </div>
  )
};

export default ButtonGroup;