import React, { useEffect, useRef } from 'react'

export default function useScrollIntoView(selected) {
    const ref = useRef()

    useEffect(() => {
        if(selected && ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth'})
        }
    }, [selected])

  return ref
}
