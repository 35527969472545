import React, { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";
import { IoIosArrowBack } from "react-icons/io";
import { BsPerson, BsHouseAdd, BsCoin } from "react-icons/bs";
import { SlSettings } from "react-icons/sl";
import { NavLink, useLocation } from "react-router-dom";
import { RiBuilding3Line } from "react-icons/ri";
import { TbReportAnalytics } from "react-icons/tb";
import { MdMenu, MdOutlineAttachEmail } from "react-icons/md";
import { LuMessageSquare } from "react-icons/lu";
import Submenu from "./Submenu";
import LoadingSpinner from "./LoadingSpinner";
import { AgentContext } from "../context/AgentContext";

export default function SideBar() {
  let isTab = useMediaQuery({ query: "(max-width: 768px)" });
  const { pathname } = useLocation();
  const { data: { activePosts, maxActivePosts  }, isLoading, userSubscription } = useContext(AgentContext);
  // sidebar open state
  const [isOpen, setIsOpen] = useState(isTab ? true : false);

  const Sidebar_animation = isTab
    ? {
        // mobile view
        open: {
          x: 0,
          width: "16rem",
          transition: {
            damping: 40,
          },
        },
        closed: {
          x: -250,
          width: 0,
          transition: {
            damping: 40,
            delay: 0.15,
          },
        },
      }
    : {
        // System view
        open: {
          width: "16rem",
          transition: {
            damping: 40,
          },
        },
        closed: {
          width: "4rem",
          transition: {
            damping: 40,
          },
        },
      };

  useEffect(() => {
    if (isTab) {
      // mobile
      setIsOpen(false);
    } else {
      // desktop
      setIsOpen(true);
    }
  }, [isTab]);

  // pathname change => close sidebar (only mobile view)
  useEffect(() => {
    isTab && setIsOpen(false);
  }, [pathname]);

  const submenuList = [
    {
      // main menu name like (all app, settings, )
      name: "build",
      // submenus
      icon: RiBuilding3Line,
      menus: ["auth", "app settings", "storage", "hosting"],
    },
    {
      name: "analytics",
      icon: TbReportAnalytics,
      menus: ["dashboard", "realtime", "events"],
    },
  ];
  return (
    <>
      <div
        className={`fixed md:hidden inset-0 max-h-screen z-[998] bg-black/50 ${
          isOpen ? "block" : "hidden"
        }`}
        onClick={() => setIsOpen(false)}
      ></div>
      <motion.div
        variants={Sidebar_animation}
        initial={{ x: isTab ? -250 : 0 }}
        animate={isOpen ? "open" : "closed"}
        className="bg-white shadow-xl w-[16rem] max-w-[16rem] h-[100vh] overflow-hidden
            text-gray z-[999] md:relative fixed"
      >
        {/* Logo */}
        <div className="flex items-center gap-2.5 font-medium border-b border-slate-300 py-3 mx-3">
          <img src="/images/icons8-house-48.png" alt=".." width={45} />
          <span className="text-xl whitespace-pre">Listing Los Cabos</span>
        </div>

        {/* Menus   */}
        <div className="flex flex-col h-full">
          {/* first */}
          <ul
            className="whitespace-pre px-2.5 text-[0.9rem] font-medium py-5 flex flex-col gap-1 
        overflow-x-hidden scrollbar-thin scrollbar-track-white scrollbar-thumb-slate-100 h-[70%] md:h-[68%]"
          >
            <li>
              <NavLink to={"/profile-agent"} className={"link"}>
                <BsPerson size={23} className="min-w-max" />
                Profile
              </NavLink>
            </li>
            <li>
              <NavLink to={"/listings-agent"} className={"link"}>
                <BsHouseAdd size={23} className="min-w-max" />
                Listings
              </NavLink>
            </li>
            {/*<li>
              <NavLink to={"/send-listings"} className={"link"}>
                <MdOutlineAttachEmail size={23} className="min-w-max" />
                Send Listings
              </NavLink>
            </li>
            <li>
              <NavLink to={"/messages"} className={"link"}>
                <LuMessageSquare size={23} className="min-w-max" />
                Messages
              </NavLink>
            </li>*/}
            {/* with submenu */}
            {/* mobile view should show submenus */}
            {(isOpen || isTab) && (
              <div className="border-y py-5 border-slate-300">
                <small className="pl-3 text-slate-500 inline-block mb-2">
                  Product categories
                </small>
                {submenuList?.map((menu) => (
                  <div key={menu.name} className="flex flex-col gap-1">
                    <Submenu data={menu}></Submenu>
                  </div>
                ))}
              </div>
            )}

            <li>
              <NavLink to={"/subscription"} className={"link"}>
                <BsCoin size={23} className="min-w-max" />
                Subscription
              </NavLink>
            </li>
            <li>
              <NavLink to={"/profile-agent"} className={"link"}>
                <SlSettings size={23} className="min-w-max" />
                Settings
              </NavLink>
            </li>
          </ul>
          {/* second */}
          {isOpen && (
            <div className="flex-1 text-sm z-50 max-h-48 my-auto whitespace-pre w-full font-medium">
              <div className="flex items-center justify-between border-y border-slate-300 p-4">
                <div>
                  <div className="flex flex-row space-x-1 text-xs">
                    <span className="font-semibold">Subscription:</span> 
                    {isLoading? <LoadingSpinner width="4" height="4"/> : <span className="text-blue-500">{userSubscription?.title ?? '-'}</span> } 
                  </div>
                  <div className="flex flex-row space-x-1 text-xs">
                    <span className="font-semibold">My products available:</span> 
                    {isLoading? <LoadingSpinner width="4" height="4"/> : <span className="text-blue-500">{activePosts}/{maxActivePosts}</span> }
                  </div>
                </div>
              </div>
            </div>
          )}
          <div></div>
        </div>

        {/* Controll button */}
        <motion.div
          animate={
            isOpen
              ? {
                  x: 0,
                  y: 0,
                  rotate: 0,
                }
              : {
                  x: -10,
                  y: -200,
                  rotate: 180,
                }
          }
          transition={{ duration: 0 }}
          onClick={() => setIsOpen(!isOpen)}
          className="absolute h-fit w-fit z-50 right-2 bottom-5 cursor-pointer hidden md:block"
        >
          <IoIosArrowBack size={25} />
        </motion.div>
      </motion.div>
      <div className="m-3 md:hidden" onClick={() => setIsOpen(true)}>
        <MdMenu size={25} />
      </div>
    </>
  );
}
