import { useState, useEffect } from "react";
import { collection, getDocs, query, where, updateDoc, doc } from 'firebase/firestore'
import { db } from '../firebase';

export function useFetchLikedItems(){

   const [likedItems, setLikedItems] = useState([]);
   const [visitor, setVisitor] = useState(null);

   useEffect(() => {
      fetchLikedItems();
  }, []);

  const fetchLikedItems = async () => {
    try {
      const visitorEmail = JSON.parse(sessionStorage.getItem('visitor'));

      if (!visitorEmail) {
        console.error('Visitor email not found in sessionStorage');
        return;
      }

      const visitorRef = collection(db, 'visitors');
      const q = query(visitorRef, where('email', '==', visitorEmail));
      const querySnap = await getDocs(q);

      if (!querySnap.empty) {
        // Find the visitor document with the matching email
        let matchingVisitor = null;

        querySnap.forEach((doc) => {
          const visitorData = doc.data();
          if (visitorData.email === visitorEmail) {
            matchingVisitor = doc;
          }
        });

        if (matchingVisitor) {
          const currentArray = matchingVisitor.data().liked || [];
          setLikedItems(currentArray); // Initialize likedItems state
          setVisitor(matchingVisitor);
        } else {
          console.error('Matching visitor not found');
        }
      } else {
        console.error('No visitor documents found');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const toggleLike = async (id) => {
    if (likedItems.includes(id)) {
      setLikedItems(likedItems.filter((itemId) => itemId !== id));
    } else {
      setLikedItems([...likedItems, id]);
    }

    if(visitor) {
      const visitorRef = collection(db, 'visitors');
      const visitorDocRef = doc(visitorRef, visitor.id);
      await updateDoc(visitorDocRef, { liked: likedItems });
    }

  };

   return { fetchLikedItems, toggleLike, likedItems };

}