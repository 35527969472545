import React, { createRef, useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import profileImgPlaceholder from "../assets/profile.png";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { InputText } from "../components/InputText";
import Spinner from "../components/Spinner";
import useProfileAgent from "../hooks/useProfileAgent";
import { useFetchUserById } from "../hooks/useFetchUserById";

export default function ProfileAgent() {
  const navigate = useNavigate();
  const { updateProfileAgent, isLoading } = useProfileAgent();
  const { fetchFilteredData: fetchUserData } = useFetchUserById({});
  const fileRef = createRef();

  const [authenticatedUser, setAuthenticatedUser] = useState(null);
  const auth = getAuth();

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      const fetchedUser = await fetchUserData(user.uid);
      setAuthenticatedUser(fetchedUser?.data);
    });
  }, []);

  // LOGOUT
  function handleLogout() {
    auth.signOut();
    navigate("/");
  }

  const handleImageUpload = async (e) => {
    const imageFile = e.target.files[0];

    if (imageFile) {
      const storage = getStorage();
      const fileName = `${auth.currentUser.uid}-${imageFile.name}-${uuidv4()}`;
      const storageRef = ref(storage, fileName);
      const uploadTask = uploadBytesResumable(storageRef, imageFile);
      try {
        await uploadTask;

        const downloadUrl = await getDownloadURL(storageRef);

        const data ={ ...authenticatedUser, profileImage: downloadUrl };
        await updateProfileAgent(data);

        setAuthenticatedUser(data);

        toast.success("Profile image updated successfully");
      } catch (error) {
        console.error("Error uploading image:", error);
        toast.error("Error uploading image");
      }
    }
  };

  const handleInputChange = (key, value) => {
    const user = { ...authenticatedUser };
    setAuthenticatedUser({
      ...user,
      [key]: value
    });
  }

  const handleSubmit = async () => {
    try {
      await updateProfileAgent(authenticatedUser);
      toast.success("Profile updated successfully");
    } catch(error) {
      console.error("Error updating profile:", error);
      toast.error("Error updating profile");
    }
  };

  const handleOnClickImage = () => {
    fileRef.current.click();
  }

  return (
    <main className="flex-1 min-w-0">
      <section className="w-full mx-auto flex flex-col md:flex-row">
        { isLoading && <Spinner /> }
        <div className="basis-1 lg:basis-2/4 xl:basis-1/4 flex flex-col justify-center items-center">
          <div
            className="p-4 text-lg cursor-pointer text-blue-400 hover:text-blue-500"
            onClick={handleLogout}
          >
            Logout
          </div>

          <div className="flex space-x-2">
            {authenticatedUser  ? (
              <p className="text-base font-semibold text-center mb-2">{authenticatedUser?.firstName} {authenticatedUser?.lastName}</p>
            ) : (
              ""
            )}
          </div>

          <div className="bg-white h-60 w-60 flex items-center justify-center">
            <input
                id="image-upload"
                type="file"
                accept=".jpg,.jpeg,.png"
                onChange={handleImageUpload}
                required
                style={{ display: "none" }}
                ref={fileRef}
              />

              <img
                className="w-full h-full object-cover cursor-pointer"
                src={authenticatedUser?.profileImage || profileImgPlaceholder}
                alt="Profile identification draw"
                onClick={handleOnClickImage}
              />
          </div>
        </div>
        <div className="basis-1 lg:basis-2/4 xl:basis-1/4  flex flex-col justify-center items-center pr-10 xl:pr-0">
          <InputText 
            title="Company" 
            id="company" 
            value={authenticatedUser?.company ?? ""} 
            onChange={(e) => handleInputChange("company", e.target.value)} 
          />

          <InputText 
            title="Website" 
            id="website" 
            value={authenticatedUser?.website ?? ""} 
            onChange={(e) => handleInputChange("website", e.target.value)}  
          />

          <InputText 
            title="Whatsapp Number" 
            id="whatsappNumber" 
            value={authenticatedUser?.whatsappNumber ?? ""} 
            onChange={(e) => handleInputChange("whatsappNumber", e.target.value)} 
          />

          <button
            type="submit"
            className="bg-[#18446b] hover:bg-[#18446b] text-white text-sm px-4 py-2 mt-5 font-semibold uppercase rounded"
            onClick={handleSubmit}
          >
            Update Profile
          </button>
        </div>
      </section>
    </main>
  );
}
