export const InputText = ({ title, id, value, disabled = false, onChange, inputRef, inputClassName = '' }) => {
  return (
    <div className="w-full">
      <p disabled={disabled} className="text-md mt-6 disabled:text-gray-400">{title}</p>

      <div>
        <input
          ref={inputRef}
          disabled={disabled}
          onChange={onChange}
          id={id}
          value={value}
          className={`${inputClassName} border-gray-300 focus:border-[#18446b] w-full transtion duration-150 ease-in-out text-sm disabled:cursor-default disabled:bg-gray-200 disabled:border-gray-200`}
          type="text"
        />
      </div>
    </div>
  );
};
