import { useContext, useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import axios from "axios";
import { toast } from "react-toastify";
import { db } from "../firebase";
import { USERS_COLLECTION } from "../shared/firebaseCollections";
import { AgentContext } from "../context/AgentContext";

export default function useSubscription() {
  const auth = getAuth();
  const [ user, setUser ] = useState(null);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ subscription, setSubscription ] = useState(null);
  const [ idToken, setIdToken ] = useState('');
  const { fetchFilteredData } = useContext(AgentContext);

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        setIdToken(await user.getIdToken());
        // Retrieve user data from Firestore
        const userRef = doc(db, USERS_COLLECTION, user.uid);
        const docSnap = await getDoc(userRef);

        if (docSnap.exists) {
          const userData = docSnap.data();
          if (userData.stripeSession && userData.stripeSession.subscription) {
            console.log(`[DEBUG] useSubscription():User ${JSON.stringify(userData)}`);
            setUser(userData);
          } else{
            setUser(null);
            setIsLoading(false);
          }
        }
      } else {
        setUser(null);
        setIsLoading(false);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    try {
      const fetchData = async () =>  {
        setIsLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/stripe/subscription/${user?.stripeSession?.subscription}`,
          {
            headers: {
              'Authorization': `Bearer ${idToken}`
            }
          }
        );
  
        const { data } = response.data;
        setSubscription(data);
        console.log("subscription: ", data);
        setIsLoading(false);
      }
      user && user.stripeSession && user.stripeSession.subscription && idToken !== '' && fetchData();
    } catch (error) {
      console.error("Subscription Error", error);
      setIsLoading(false);
    }
  }, [user, idToken]);

  const startCheckout = async (stripePrice) => {
    console.log("stripe price -> ", stripePrice);
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/stripe/checkout-subscription`,
        {
          stripePrice,
        },
        {
          headers: {
            'Authorization': `Bearer ${idToken}`
          }
        }
      );

      const { data: session } = response.data;
      console.log("Redirecting to session URL: ", session.url);
      window.location.href = session.url;
      setIsLoading(false);
      return null;
    } catch (error) {
      console.error("Checkout Error", error);
      setIsLoading(false);
    }
  };

  const cancelSubscription = async (subscriptionId) => {
    console.log("subscriptionId -> ", subscriptionId);
    try {
      setIsLoading(true);
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/stripe/subscription/${subscriptionId}`,
        {
          headers: {
            'Authorization': `Bearer ${idToken}`
          }
        }
      );

      console.log("cancel subscription user result: ", response?.data?.message);
      setSubscription(null);
      setIsLoading(false);

      toast.success("Subscription cancelled successfully");
      
      // update agent context - we need that the hook update
      setTimeout(() => {
        fetchFilteredData();
      },[4000]);

      return null;
    } catch (error) {
      console.error("Cancel Subscription Error", error);
      setIsLoading(false);

      toast.error("Error on cancel subscription");
    }
  };

  return { 
    isLoading, 
    subscription, 
    startCheckout, 
    cancelSubscription 
  };
}
