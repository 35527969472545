import { useState, useEffect } from "react";
import { collection, getDocs, query, where, or } from 'firebase/firestore'
import { db } from '../firebase';
import { LISTINGS_COLLECTION } from "../shared/firebaseCollections";

export function useFetchListing({ filters = {} }){

   const [data, setData] = useState([]);
   const [error, setError] = useState(null);
   const [loading, setLoading] = useState(false);
   const filtersString = JSON.stringify(filters);

   useEffect(() => {
      fetchFilteredData();
  }, [filtersString]);

   const fetchFilteredData = async () => {
      try {
         setLoading(true);
         let q = collection(db, LISTINGS_COLLECTION);

         // only show enabled listings
         q = query(q, where('enabled', '==', true));
         
         // apply filters
         Object.keys(filters).forEach(key => {
            if(filters[key]) {

               if (['type', 'property'].includes(key)) {

                  const value = filters[key];
                  // filter by that filter
                  q = query(q, where(key, '==', value));

               } else if(key === 'searchBy') {

                  const value = filters[key]?.toLowerCase();
                  q = query(q, or(
                     where('location.country', '==', value),
                     where('location.state', '==', value),
                     where('location.municipality', '==', value),
                     where('location.cologne', '==', value),
                     where('location.street', '==', value),
                     where('location.cp', '==', value),
                  ));

               } else if (['bedrooms', 'bathrooms', 'garage', 'antiquity', 'community'].includes(key)) {

                  const value = filters[key];
                  q = query(q, where(key, '==', value));

               } else if(key === 'maxPrice') {

                  const value = parseInt(filters[key]);
                  q = query(q, where('regularPrice', '<=', value));

               } else if(key === 'minPrice') {

                  const value = parseInt(filters[key]);
                  q = query(q, where('regularPrice', '>=', value));

               }
            }
         });

         const querySnap = await getDocs(q);
         const listings = querySnap.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
         }));

         setData(listings);
      } catch (error) {
        console.log(error);
        setError(error);
      } finally{
         setLoading(false);
      }
   }

   return { fetchFilteredData, data, loading, error };

}