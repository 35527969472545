import basic from "../assets/real_estate_plan.jpg";

export const OPERATION_TYPES = [
  { key: 'sale', value: 'Buy' },
  { key: 'rent', value: 'Rent' },
  { key: 'developments', value: 'Developments' }
];

export const FACILITY_TYPES = [
  { key: 'apartment', value: 'Apartment' },
  { key: 'house', value: 'House' },
  { key: 'condominium', value: 'Condominium' },
  { key: 'commercial_space', value: 'Commercial space' },
  { key: 'commercial_lot', value: 'Comercial lot' }
];

export const COMMUNITIES = [
  {
    href:'/community/cabo-del-sol',
    img: 'https://www.windermereloscabos.com/thumbs/416x284/uploads/Cabo%20Del%20Sol.jpeg',
    label: 'Cabo del Sol'
  },
  {
    href:'/community/diamante',
    img: 'https://www.windermereloscabos.com/thumbs/416x284/uploads/unnamed-2.91.jpg',
    label: 'Diamante'
  },
  {
    href:'/community/cabo-bello',
    img: 'https://www.windermereloscabos.com/thumbs/416x284/uploads/Cabo%20Bello%20Header%20v2.30.jpg',
    label: 'Cabo Bello'
  },
  {
    href:'/community/querencia',
    img: 'https://www.windermereloscabos.com/thumbs/416x284/uploads/Querencia.jpeg',
    label: 'Querencia'
  },
  {
    href:'/community/quivira-hub',
    img: 'https://www.windermereloscabos.com/thumbs/416x284/uploads/Quivira%20View.jpg',
    label: 'Quivira Hub'
  },
  {
    href:'/community/palmilla',
    img: 'https://www.windermereloscabos.com/thumbs/416x284/uploads/Palmilla.jpeg',
    label: 'Palmilla'
  }
];

export const CONTACTS = [
  { label: "Our Office", href: "/our-office" },
  { label: "Contacto", href: "/contacto" },
  { label: "Our agents", href: "/our-agents" },
];

export const ICONS = [
  {
    name: "logo-facebook",
    link: "/",
    src: "/images/iconos/Facebook-Contorno dorado transparente.png",
    hover: "/images/iconos/Facebook-Dorado-Blanco.png",
  },
  {
    name: "logo-instagram",
    link: "/",
    src: "/images/iconos/Instagram-Contorno dorado transparente.png",
    hover: "/images/iconos/Instagram-Dorado-Blanco.png",
  },
  {
    name: "logo-tiktok",
    link: "/",
    src: "/images/iconos/TikTok-Contorno dorado transparente.png",
    hover: "/images/iconos/TikTok-Dorado-Blanco.png",
  },
  {
    name: "logo-twitter",
    link: "/",
    src: "/images/iconos/Twitter-Contorno dorado transparente.png",
    hover: "/images/iconos/Twitter-Dorado-Blanco.png",
  },
];

export const LISTINGS = [
  { label: "Listings", href: "/listings" },
  { label: "Developer Zone", href: "/developer-zone" },
  { label: "L.C. Listings Opportunities", href: "/listings-opportunities" },
];

export const CITIES = [
  { label: "Los Cabos", href: "/" },
  { label: "Baja California", href: "/baja-california" },
  { label: "Acapulco", href: "/acapulco" },
];

export const FEATURES_AND_AMENITIES = [
  { label: "Swimming Pool", firebaseKey: "swimmingPool" },
  { label: "Garden", firebaseKey: "garden" },
  { label: "Green Areas", firebaseKey: "greenAreas" },
  { label: "Surveillance", firebaseKey: "surveillance" },
  { label: "Internet Access", firebaseKey: "internetAccess" },
  { label: "Multipurpose Room", firebaseKey: "multipurposeRoom" },
  { label: "Terraces", firebaseKey: "terraces" },
  { label: "Service Room", firebaseKey: "serviceRoom" },
  { label: "Gym", firebaseKey: "gym" },
  { label: "Lobby", firebaseKey: "lobby" },
];

export const SUBSCRIPTION_PLANS = [
  {
    id: 1,
    src: basic,
    title: "Basic",
    description: "Ideal for independent agents or small real estate agencies",
    price: "15",
    stripePrice: "price_1Q2CvNK2N6ksdlaATqaqBs51"
  },
  {
    id: 2,
    src: basic,
    title: "Standard",
    description: "Perfect for brokers and medium-sized real estate agencies",
    price: "25",
    stripePrice: "price_1Q2D6GK2N6ksdlaAkU14xVkD"
  },
  {
    id: 3,
    src: basic,
    title: "Premium",
    description: "Designed for large real estate agencies with high property turnover",
    price: "35",
    stripePrice: "price_1Q2D6xK2N6ksdlaAHFVQf3Qq"
  },
];