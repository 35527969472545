import { useEffect } from "react";
import ListingItem from "../ListingItem";
import ProgressBar from "../ProgressBar";
import { useFetchLikedItems } from "../../hooks/useFetchLikedItems";

const ListingCards = ({ loading, data, selectedListingId = "", listingRef = null }) => {
  const { fetchLikedItems, toggleLike, likedItems } = useFetchLikedItems();

  // Fetch all listings
  useEffect(() => {
    fetchLikedItems();
  }, []);

  return (
    <>
      { loading && <div className='mt-2'><ProgressBar /></div> }
      <section className="bg-gray-2 pb-5 dark:bg-dark">
        <div className="container mx-auto p-5">
          <div className="-mx-4 flex flex-wrap">
            {data.map((listing, index) => (
              <ListingItem
                key={index}
                id={listing.id}
                listing={listing.data}
                selected={listing.id === selectedListingId} // Pass whether this listing is selected
                externalRef={listingRef} // Pass the ref to ListingItem
                isLiked={likedItems.includes(listing.id)}
                onLike={() => toggleLike(listing.id)}
                toggleLike={toggleLike}
              />
              ))}
          </div>
        </div>
      </section>
    </>
  )
};

export default ListingCards;