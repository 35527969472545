import { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from '../firebase';
import { LISTINGS_COLLECTION } from "../shared/firebaseCollections";
import { useFetchUserById } from "./useFetchUserById";

export function useFetchListingById({ id }) {

   const [data, setData] = useState({});
   const [error, setError] = useState(null);
   const [loading, setLoading] = useState(true);
   const { fetchFilteredData: fetchUserData } = useFetchUserById({});

   useEffect(() => {
      fetchListingData();
   }, [id]);

   const fetchListingData = async () => {
      try {
        setLoading(true);
        const docRef = doc(db, LISTINGS_COLLECTION, id);
        const docSnap = await getDoc(docRef);
        if(docSnap.exists()){
          const data = docSnap.data();
          const user = await fetchUserData(data?.userRef);
          const listing = { id: docSnap.id, data, user: user?.data };
          setData(listing);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setError(error);
      } finally{
        setLoading(false);
      }
   };

   return { fetchListingData, data, loading, error };

}