import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import ListingItem from "../components/ListingItem";
import PropertiesMap from "../components/PropertiesMap";
import useScrollIntoView from "../hooks/useScrollIntoView";

export default function Profile() {
  const [listings, setListings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedListingId, setSelectedListingId] = useState(null);
  const [mapVisible, setMapVisible] = useState(false);
  const [likedItems, setLikedItems] = useState([]);

  // Fetch all listings
  useEffect(() => {
    async function fetchAllListings() {
      const listingRef = collection(db, "listings");
      const q = query(listingRef, orderBy("timestamp", "desc"));
      const querySnap = await getDocs(q);
      let listings = [];
      querySnap.forEach((doc) => {
        return listings.push({
          id: doc.id,
          data: doc.data(),
        });
      });
      setListings(listings);
      setLoading(false);
    }

    fetchAllListings();

    // Fetch liked items when the component mounts
    async function fetchLikedItems() {
      try {
        const visitorEmail = JSON.parse(sessionStorage.getItem("visitor"));

        if (!visitorEmail) {
          console.error("Visitor email not found in sessionStorage");
          return;
        }

        const visitorRef = collection(db, "visitors");
        const q = query(visitorRef, where("email", "==", visitorEmail));
        const querySnap = await getDocs(q);

        if (!querySnap.empty) {
          // Find the visitor document with the matching email
          let matchingVisitor = null;

          querySnap.forEach((doc) => {
            const visitorData = doc.data();
            if (visitorData.email === visitorEmail) {
              matchingVisitor = doc;
            }
          });

          if (matchingVisitor) {
            const currentArray = matchingVisitor.data().liked || [];
            setLikedItems(currentArray); // Initialize likedItems state
          } else {
            console.error("Matching visitor not found");
          }
        } else {
          console.error("No visitor documents found");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }

    fetchLikedItems();
  }, []);

  const handleMarkerClick = (listingId) => {
    setSelectedListingId(listingId);
  };

  // Use the custom hook to get a ref
  const listingRef = useScrollIntoView(selectedListingId);

  const handleToggleMap = () => {
    setMapVisible(!mapVisible); // Toggle map visibility when the button is clicked
  };

  const toggleLike = async (id) => {
    if (likedItems.includes(id)) {
      setLikedItems(likedItems.filter((itemId) => itemId !== id));
    } else {
      setLikedItems([...likedItems, id]);
    }

    try {
      const visitorEmail = JSON.parse(sessionStorage.getItem("visitor"));

      if (!visitorEmail) {
        console.error("Visitor email not found in sessionStorage");
        return;
      }

      const visitorRef = collection(db, "visitors");
      const q = query(visitorRef, where("email", "==", visitorEmail));
      const querySnap = await getDocs(q);

      if (!querySnap.empty) {
        // Find the visitor document with the matching email
        let matchingVisitor = null;

        querySnap.forEach((doc) => {
          const visitorData = doc.data();
          if (visitorData.email === visitorEmail) {
            matchingVisitor = doc;
          }
        });

        if (matchingVisitor) {
          const currentArray = matchingVisitor.data().liked || [];

          if (!currentArray.includes(id)) {
            currentArray.push(id);
          } else {
            // If the ID is already liked, remove it from the array
            const index = currentArray.indexOf(id);
            if (index !== -1) {
              currentArray.splice(index, 1);
            }
          }

          const visitorDocRef = doc(visitorRef, matchingVisitor.id);
          await updateDoc(visitorDocRef, { liked: currentArray });

          console.log("Array updated successfully", currentArray);

          // Update the likedItems state with the new liked items
          setLikedItems(currentArray);
        } else {
          console.error("Matching visitor not found");
        }
      } else {
        console.error("No visitor documents found.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="flex items-start justify-between overflow-hidden">
      <div className="overflow-y-auto w-full max-h-[85vh]">
        <h2 className="text-3xl font-semibold text-center tracking-wider mt-6 mb-6">
          Favourite Listings
        </h2>
        {/* <button
              className='ml-16 px-5 py-1 mt-3 bg-[#18446b] hover:bg-opacity-90 text-white text-xs tracking-wider'
              onClick={handleToggleMap}
              >
                {mapVisible ? 'Hide Map' : 'Show Map'}
              </button> */}
        <ul
          className={`sm:grid grid-cols-2 ${
            mapVisible ? "" : "lg:grid-cols-4"
          } `}
        >
          {listings && listings.length > 0 && (
            <>
              {listings.map(
                (listing) =>
                  // Conditionally render the listing only if it is liked
                  likedItems.includes(listing.id) && (
                    <ListingItem
                      key={listing.id}
                      id={listing.id}
                      listing={listing.data}
                      selected={listing.id === selectedListingId} // Pass whether this listing is selected
                      externalRef={listingRef} // Pass the ref to ListingItem
                      isLiked={likedItems.includes(listing.id)}
                      onLike={() => toggleLike(listing.id)}
                      toggleLike={toggleLike}
                    />
                  )
              )}
            </>
          )}
        </ul>
      </div>

      {mapVisible && (
        <div className="w-full h-[85vh] overflow-hidden">
          <PropertiesMap onMarkerClick={handleMarkerClick} />
        </div>
      )}
    </div>
  );
}
