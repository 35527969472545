import { useState } from "react";
import { Carousel } from "./Carousel";

export const ListingCarousel = ({ imgFiles, videoFiles }) => {
  const [ openCarousel, setOpenCarousel ] = useState(false);
  const showImages = 5;

  const handleOpenCarousel = () => {
    setOpenCarousel(!openCarousel);
  };

  const renderImage = ({ imgFiles, i }) => {
    return (
      <img
        key={`image_${i}`}
        src={imgFiles ? imgFiles[i]?.savedUrl : "#"}
        alt={imgFiles ? imgFiles[i]?.name : "unamed"}
        className="object-cover w-full h-full cursor-pointer"
        onClick={handleOpenCarousel}
      />
    );
  };

  const renderVideo = ({ videoFiles, i }) => {
    return (
      <div className="relative w-full h-full cursor-pointer" onClick={handleOpenCarousel}>
        <video className="object-cover w-full h-full">
          <source 
            src={videoFiles ? videoFiles[i]?.savedUrl : "#"}
            alt={videoFiles ? videoFiles[i]?.name : "unamed"} />
        </video>
        <div
          className={`absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center bg-primary bg-opacity-90`}
        >
          <a className="absolute z-20 flex h-20 w-20 items-center justify-center rounded-full text-primary dark:bg-dark-2 dark:text-white md:h-[100px] md:w-[100px]">
            <span className="absolute right-0 top-0 z-[-1] h-full w-full rounded-full bg-white bg-opacity-50"></span>
            <svg
              width="23"
              height="27"
              viewBox="0 0 23 27"
              className="fill-current"
            >
              <path d="M22.5 12.634C23.1667 13.0189 23.1667 13.9811 22.5 14.366L2.25 26.0574C1.58333 26.4423 0.750001 25.9611 0.750001 25.1913L0.750002 1.80866C0.750002 1.03886 1.58334 0.557731 2.25 0.942631L22.5 12.634Z" />
            </svg>
          </a>
        </div>
      </div>
    );
  }

  const renderElements = () => {
    var elements = [];
    for (var i = 0; i < showImages; i++) {
      if(!i) {
        elements.push(
          <div key={i} className="row-span-6 col-span-4">
            {renderImage({ imgFiles, i })}
          </div>
        );
      } else if(i === showImages - 1) {
        elements.push(
          <div key={i} className="row-span-3 col-span-2">
            {renderVideo({ videoFiles, i: 0 })}
          </div>
        );
      } else {
        elements.push(
          <div key={i} className="row-span-3 col-span-2">
            {renderImage({ imgFiles, i })}
          </div>
        );
      }
    }
    return elements;

  };

  return (
    <>
      <div className="grid place-items-center">
        <div className="grid grid-rows-6 grid-cols-8 gap-1 w-full h-96 rounded-md overflow-hidden">
          {imgFiles ? renderElements() : "Loading..."}
        </div>
      </div>
      <Carousel 
        imgFiles={imgFiles} 
        videoFiles={videoFiles} 
        openCarousel={openCarousel} 
        onClose={() => setOpenCarousel(false)} />
    </>
  )
}