import { getAuth } from 'firebase/auth'
import { collection, doc, getDoc, getDocs, orderBy, query, where } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { db } from '../firebase'
import axios from 'axios'
import { toast } from 'react-toastify'

export default function SendListings() {
  const [ loading, setLoading ] = useState(true);
  const [ listings, setListings ] = useState(null);
  const auth = getAuth();
  const [ clientEmail, setClientEmail ] = useState('');
  const [ planType, setPlanType ] = useState('');
  const [ idToken, setIdToken ] = useState('');

  // DISPLAY AGENT PLAN TYPE
  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        setIdToken(await user.getIdToken());
        const userRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          const userData = docSnap.data()
          if (userData.subscription) {
            setPlanType(userData.subscription.planType || '')
          }
        }
      }
    });
  }, []);
  

  // FETCH USER LISTINGS
  useEffect(() => {
    async function fetchUserListings() {
      const listingRef = collection(db, 'listings');
      const q = query(listingRef, orderBy('timestamp', 'desc'));
      const querySnap = await getDocs(q);

      let listings = [];
      querySnap.forEach((doc) => {
        return listings.push({
          id: doc.id,
          data: doc.data()
        });
      })
      setLoading(false);
      setListings(listings);
    };
    fetchUserListings();
  }, [auth.currentUser.uid] );

  const handleSubmit = async (e) => {
    e.preventDefault();

    const selectedListings = listings.filter((listing) => listing.selected);

    if(selectedListings.length > 0 && clientEmail) {
      try {
        // Modify each listing to include address and regularPrice
        const modifiedListings = selectedListings.map((listing) => ({
          ...listing,
          address: listing.data.address,
          regularPrice: listing.data.regularPrice,
          imgUrls: listing.data?.imgFiles[0]?.savedUrl,
        }));
        
        if (true) {
            await axios.post(
              `${process.env.REACT_APP_BACKEND_URL}/api/v1/email/send-listings-emails`, 
              {
                clientEmail, 
                listings: modifiedListings,
              },
              {
                headers: {
                  'Authorization': `Bearer ${idToken}`
                }
              });
          toast.success(`Listings email sent successfully to ${clientEmail}`)
          setClientEmail('')
        } else {
          toast.info('You should get a subscription to send Listings')
        }

      } catch (error) {
        console.error('Error sending listings email', error)
        toast.error(`Error sending listings email, please try again.`)
      }
    } else {
      toast.error("Please select listings, and provide client's email address")
    }
    
  };

  const handleClientEmailChange = (e) => {
    setClientEmail(e.target.value)
  };

  const handleRadioChange = (index) => {
    const updatedListings = [...listings]
    updatedListings[index].selected = !updatedListings[index].selected
    setListings(updatedListings)
  };

  return (
    <main className="flex-1 min-w-0">
      <div className='h-full p-5 mt-14'>
        {/* DESKTOP */}

        <div className=' rounded-lg shadow md:block overflow-x-auto'>
          <div className='mb-2'>
            <input
            className='py-[0.9vh]' 
            onChange={handleClientEmailChange}
            value={clientEmail}
            type="text" 
            placeholder='Insert Client Email' />
            <button
            onClick={handleSubmit} 
            className='px-6 py-2 bg-[#18446b] hover:opacity-70 text-white'>Send Listings</button>
          </div>
          
          <table className='w-full'>
            <thead className='bg-gray-50 border-b-2 border-gray-200'>
                <tr>
                  <th className='p-3 text-sm font-semibold tracking-wide text-left'>
                    Dirección
                  </th>
                  <th className='p-3 text-sm font-semibold tracking-wide text-left'>
                    Precio
                  </th>
                  <th className='p-3 text-sm font-semibold tracking-wide text-left'>
                    Area
                  </th>
                  <th className='p-3 text-sm font-semibold tracking-wide text-left'>
                    Recamaras
                  </th>
                  <th className='p-3 text-sm font-semibold tracking-wide text-left'>
                    Baños
                  </th>
                  <th className='p-3 text-sm font-semibold tracking-wide text-left'>
                    Alberca
                  </th>
                  <th className='p-3 text-sm font-semibold tracking-wide text-left'>
                    Estacionamiento
                  </th>
                  <th className='p-3 text-sm font-semibold tracking-wide text-left'>
                    Seleccionados
                  </th>
                </tr>
            </thead>

            <tbody className='divide-y divide-gray-100'>
              {listings && listings.length > 0 && (
                listings.map((listing, index) => (
                  <tr key={index} className='bg-white'>
                      <td className='p-3 text-sm text-gray-700 whitespace-nowrap'>
                        <p className='text-sm font-medium '>{listing.data.address}</p>
                      </td>
                      <td className='p-3 text-sm text-gray-700 whitespace-nowrap'>
                        <p className='text-sm font-medium '>${listing.data.regularPrice}</p>
                      </td>
                      <td className='p-3 text-sm text-gray-700 whitespace-nowrap'>
                        <p className='text-sm font-medium '>{listing.data.totalArea}/{listing.data.usefulArea}</p>
                      </td>
                      <td className='p-3 text-sm text-gray-700 whitespace-nowrap'>
                        <p className='text-sm font-medium '>{listing.data.bedrooms}</p>
                      </td>
                      <td className='p-3 text-sm text-gray-700 whitespace-nowrap'>
                        <p className='text-sm font-medium '>{listing.data.bathrooms}</p>
                      </td>
                      <td className='p-3 text-sm text-gray-700 whitespace-nowrap'>
                        <p className='text-sm font-medium '>{listing.data.swimmingPool}</p>
                      </td>
                      <td className='p-3 text-sm text-gray-700 whitespace-nowrap'>
                        <p className='text-sm font-medium '>{listing.data.parkings}</p>
                      </td>
                      <td className='p-3 text-sm text-gray-700 whitespace-nowrap'>
                        <input 
                        type="radio" 
                        name={`listing-${index}`}
                        id={`listing-${index}`}
                        checked={listing.selected}
                        onChange={() => handleRadioChange(index)} />
                      </td>
                  </tr>
                ))
              )}
              
            </tbody>
          </table>

        </div>
      </div>
    </main>
  );
}
