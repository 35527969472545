import React, { useEffect, useState } from "react";
import { IoIosOptions } from "react-icons/io";
import { AiOutlineSearch } from "react-icons/ai";
import { useParams } from "react-router-dom";

import ProgressBar from "../components/ProgressBar";
import ListingCards from "../components/ListingCards";

import { useFetchCommunity } from "../hooks/useFetchCommunity";
import { useFetchListing } from "../hooks/useFetchListing";

export default function Community({}) {
  const [activeTab, setActiveTab] = useState("Overview");
  const [ communitySlug, setCommunitySlug ] = useState("");
  
  const { communityName } = useParams();
  const { data: { id: communityId, data: community }, loading } = useFetchCommunity({ communitySlug });
  const { data: listings, loading: loadingListing } = useFetchListing({ filters: { community: communityId } });

  const tabs = ["Overview", "Real State Statistics", "Listings"];

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    setCommunitySlug(communityName);
  }, [ communityName ]);

  return (
    <div className="w-full font-montserrat">
      { loading && <div className='mt-2'><ProgressBar /></div> }
      { !loading &&
      <>
        <div
          id="background-image"
          style={{ backgroundImage: `url(${community?.imgCoverUrl})` }}
          className="flex flex-col w-full h-fit md:h-[600px] bg-no-repeat bg-cover bg-center items-center md:items-start justify-end py-36 md:px-2 lg:px-20"
        >
          <h1 className="text-4xl md:text-5xl text-white uppercase font-palatino-lt-serif font-medium mb-2">
            {community?.title}
          </h1>

          {/*<div className="flex items-center flex-col lg:flex-row justify-center md:space-x-4 bg-white w-fit h-fit lg:h-20 p-3">
            <div className="flex w-full md:w-auto">
              <select
                className="p-5 hover:bg-[#18446b] focus:outline-none hover:text-white duration-300 w-full md:w-auto"
                name=""
                id=""
              >
                <option value="Buy">Buy</option>
                <option value="Sell">Sell</option>
              </select>

              <div className="md:flex md:items-center md:justify-start hidden">
                <p className="text-3xl font-light text-gray-300 pl-3">|</p>
              </div>
            </div>

            <input
              className="focus:outline-none border border-transparent focus:border-cyan-600 p-5 "
              placeholder="City, Address, Zip"
              type="text"
            />

            <div className="md:flex md:items-center md:justify-end hidden">
              <p className="text-3xl font-light text-gray-300">|</p>
            </div>

            <div className="relative">
              <button
                className="flex items-center justify-center hover:bg-[#18446b]
                      text-black hover:text-white text-base p-5 font-medium  duration-300 group"
              >
                Filters
                <IoIosOptions
                  className=" text-black group-hover:text-white text-lg ml-2
                          duration-300"
                />
              </button>
            </div>

            <div className="relative">
              <button
                className="flex items-center justify-center bg-[#18446b] hover:bg-opacity-70 
                      text-white p-5 font-medium text-base uppercase tracking-widest duration-300"
              >
                <AiOutlineSearch className="text-white text-lg mr-2 duration-300" />
                Start Search
              </button>
            </div>
          </div>*/}
        </div>

        {/*<div className="bg-white border-b shadow-sm top-0 z-40">
          <div className={`flex flex-col lg:flex-row items-center justify-between px-4 lg:px-40 py-4`}>
            <ul className="flex justify-between space-x-4 my-4 lg:my-0">
              {tabs.map((tab) => (
                <li
                  key={tab}
                  className={`cursor-pointer ${
                    activeTab === tab
                      ? "border-b border-gray-500 duration-500"
                      : ""
                  }`}
                  onMouseEnter={() => handleTabClick(tab)}
                >
                  {tab}
                </li>
              ))}
            </ul>

            <div className="flex space-x-4">
              <button
                className="bg-blue-700 hover:bg-blue-500 duration-300
                      px-9 py-4 rounded-full text-white uppercase text-xs font-medium"
              >
                Search Property
              </button>
              <button
                className="bg-green-700 hover:bg-green-500 duration-300
                      px-9 py-4 rounded-full text-white uppercase text-xs font-medium"
              >
                Contact Agent
              </button>
            </div>
          </div>
        </div>*/}

        <div className="flex flex-wrap items-center justify-center h-[100vh] space-y-4 md:space-y-0 md:space-x-10 px-6">
          <div className="">
            <h1 className="text-4xl font-medium text-center md:text-start mb-8 ">
              {community?.overview.title}
            </h1>
            <p className="text-base text-black font-light leading-8 mb-8" dangerouslySetInnerHTML={{__html: community?.overview.description }} />
          </div>
          <div>
            <img className="h-[500px]" src={community?.overview.imgUrl} alt={community?.overview.title} />
          </div>
        </div>

        <div className='overflow-y-auto w-full max-h-[85vh]'>
          <ListingCards 
            loading={loadingListing} 
            data={listings} 
          />
        </div>
      </>
      }
    </div>
  );
}
