import React, { useEffect, useState } from "react";
import Spinner from "../components/Spinner";
import { toast } from "react-toastify";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { doc, getDoc, serverTimestamp, updateDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { v4 as uuidv4 } from "uuid";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../firebase";
import useAuthStatus from "../hooks/useAuthStatus";
import { useFetchCommunities } from "../hooks/useFetchCommunities";
import { CreateListingComponent } from "../components/CreateListing";
import { LocationListingComponent } from "../components/LocationListing";
import { DescriptionListingComponent } from "../components/DescriptionListing";
import { FeatureAndAmenitiesListingComponent } from "../components/FeatureAndAmenitiesListing";
import { FileListingComponent } from "../components/FileListing";
import { FACILITY_TYPES, OPERATION_TYPES } from "../shared/constants";

export default function EditListing() {
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const params = useParams();
  const auth = getAuth();
  const { isAdmin } = useAuthStatus();
  const [listing, setListing] = useState(null);
  const { data: communities } = useFetchCommunities({});

  const handleNext = () => {
    setActiveStep((nexStep) => nexStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const [formData, setFormData] = useState({
    type: OPERATION_TYPES[0].key,
    totalArea: 0,
    usefulArea: 0,
    regularPrice: 0,
    title: "",
    description: "",
    images: [],
    videos: [],
    adress: "",
    outdoorNumber: 0,
    interiorNumber: 0,
    cp: 0,
    property: FACILITY_TYPES[0].key,
    bedrooms: 0,
    bathrooms: 0,
    garage: false,
    antiquity: 0,
    swimmingPool: false,
    garden: false,
    greenAreas: false,
    terraces: false,
    lobby: false,
    surveillance: false,
    internetAccess: false,
    gym: false,
    serviceRoom: false,
    multipurposeRoom: false,
    community: ""
  });

  const {
    type,
    totalArea,
    usefulArea,
    regularPrice,
    title,
    description,
    images,
    videos,
    property,
    bedrooms,
    bathrooms,
    garage,
    antiquity,
    swimmingPool,
    garden,
    greenAreas,
    terraces,
    lobby,
    surveillance,
    internetAccess,
    gym,
    serviceRoom,
    multipurposeRoom,
    location,
    community
  } = formData;

  // dropdown
  function handleChange(e) {
    let boolean = null;

    // Define boolean
    if (e.target.value === "true") {
      boolean = true;
    }

    if (e.target.value === "false") {
      boolean = false;
    }

    // Text & Boolean & Number
    if (!e.target.files) {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: boolean ?? e.target.value,
      }));
    }
  }

  function handleLocationChange(e) {
    setFormData((prevState) => ({
      ...prevState,
      ['location']: e,
    }));
  }

  function handleChangeFiles(files) {
    if (files.length > 0) {
      const images = [...files].filter((file) => file.type.includes("image"));
      const videos = [...files].filter((file) => file.type.includes("video"));

      setFormData((prevState) => {
        return {
          ...prevState,
          images: [...prevState.images, ...images],
          videos: [...prevState.videos, ...videos],
        };
      });
    }
  }

  const handleDeleteFile = (fileType, index) => {
    if(fileType.includes("image")) {
      const images = formData.images;
      images.splice(index, 1);
      setFormData({
        ...formData,
        images
      });
    } else if(fileType.includes("video")) {
      const videos = formData.videos;
      videos.splice(index, 1);
      setFormData({
        ...formData,
        videos
      });
    }
  };

  // only the owner can edit this listing
  useEffect(() => {
    if (!isAdmin) {
      if (listing && listing.userRef !== auth.currentUser.uid) {
        toast.error("You cant edit this Listing");
        navigate("/");
      }
    }
  }, [auth.currentUser.uid, listing, navigate]);

  useEffect(() => {
    setLoading(true);
    async function fetchListing() {
      const docRef = doc(db, "listings", params.listingId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setListing(docSnap.data());

        // Update formData with existing imgUrls
        setFormData({
          ...docSnap.data(),
          images: docSnap.data().imgFiles || [], // Initialize as an empty array
          videos: docSnap.data().videoFiles || [],
        });
        setLoading(false);
      } else {
        toast.error("Listing does not exist");
        navigate("/");
      }
    }
    fetchListing();
  }, [navigate, params.listingId]);

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    if (images.length > 7) {
      setLoading(false);
      toast.error("Maximum 6 images are allowed");
      return;
    }

    if (videos.length > 2) {
      setLoading(false); 
      toast.error("Maximum 1 video are allowed");
      return;
    }
  
    async function storeImage(image) {
      return new Promise((resolve, reject) => {
        const storage = getStorage();
        const fileName = `${auth.currentUser.uid}-${image.name}-${uuidv4()}`;
        const storageRef = ref(storage, fileName);
        const uploadTask = uploadBytesResumable(storageRef, image);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Observe state change events such as progress, pause and resume
            // Get task progress, including the number of bytes uploaded and the total of bytes to be uploaded
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            // eslint-disable-next-line default-case
            switch (snapshot.state) {
              case "paused":
                console.log("Upload is paused");
                break;
              case "running":
                console.log("Upload is running");
                break;
            }
          },
          (error) => {
            // Handle unsuccessful uploads
            console.error(error);
            setLoading(false);
            toast.error("Cannot save images on the server. Contact with the administrator");
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
              resolve({ downloadUrl, fileName });
            });
          }
        );
      });
    }

    // Upload new images and update imgUrls
    const imgFiles = await Promise.all(
      [...images].map(async (image) => {
        if (image.file) {
          // it's not saved on the storage
          const { downloadUrl, fileName } = await storeImage(image.file);
          const { file, ...restImage } = image;
          return { ...restImage, savedUrl: downloadUrl, savedFileName: fileName };
        } else {
          // it's saved on the storage
          return image;
        }
      })
    ).catch((error) => {
      console.error(error);
      setLoading(false);
      toast.error("Images not uploaded");
      return;
    });

    const videoFiles = await Promise.all(
      [...videos].map(async (video) => {
        if (video.file) {
          // it's not saved on the storage
          const { downloadUrl, fileName } = await storeImage(video.file);
          const { file, ...restVideo } = video;
          return { ...restVideo, savedUrl: downloadUrl, savedFileName: fileName };
        } else {
          // it's saved on the storage
          return video;
        }
      })
    ).catch((error) => {
      console.error(error);
      setLoading(false);
      toast.error("Videos not uploaded");
      return;
    });

    if(!imgFiles) {
      setLoading(false);  
      toast.error("Cannot save images on the server");
    } else {
      const formDataCopy = {
        ...formData,
        imgFiles,
        videoFiles,
        timestamp: serverTimestamp(),
        userRef: auth.currentUser.uid,
      };
  
      delete formDataCopy.images;
      delete formDataCopy.videos;
  
      // Update the Firestore document
      const docRef = doc(db, "listings", params.listingId);
      await updateDoc(docRef, formDataCopy);
  
      setLoading(false);
      toast.success("Listing edited successfully");
      navigate(`/category/${formDataCopy.type}/${docRef.id}`);
    }
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="max-w-md mx-auto px-0 md:px-5">
      <form onSubmit={handleSubmit}>
        {activeStep === 0 && (
          <CreateListingComponent
            title="Edit Listing"
            options={{ communities }}
            onChange={handleChange}
            type={type}
            regularPrice={regularPrice}
            totalArea={totalArea}
            usefulArea={usefulArea}
            property={property}
            bedrooms={bedrooms}
            bathrooms={bathrooms}
            garage={garage}
            antiquity={antiquity}
            community={community}
          />
        )}

        {activeStep === 1 && (
          <LocationListingComponent
            onChange={handleLocationChange}
            listingLocation={location}
          />
        )}

        {activeStep === 2 && (
          <DescriptionListingComponent
            onChange={handleChange}
            title={title}
            description={description}
          />
        )}

        {activeStep === 3 && (
          <FeatureAndAmenitiesListingComponent
            onChange={handleChange}
            swimmingPool={swimmingPool}
            garden={garden}
            greenAreas={greenAreas}
            terraces={terraces}
            lobby={lobby}
            surveillance={surveillance}
            internetAccess={internetAccess}
            gym={gym}
            serviceRoom={serviceRoom}
            multipurposeRoom={multipurposeRoom}
          />
        )}

        {activeStep === 4 && (
          <FileListingComponent
            onChange={handleChangeFiles}
            onDeleteFile={handleDeleteFile}
            imgFiles={images}
            videoFiles={videos}
          />
        )}

        {/* buttons */}
        {activeStep < 4 && (
          <>
            <div className="flex justify-between mt-6 mb-6">
              <button
                type="button"
                onClick={handleBack}
                className="bg-[#18446b] hover:bg-[#18446b] text-white text-sm px-4 py-2
                            font-semibold uppercase rounded"
              >
                Back
              </button>
              <button
                type="button"
                onClick={handleNext}
                className="bg-[#18446b] hover:bg-[#18446b] text-white text-sm px-4 py-2
                            font-semibold uppercase rounded"
              >
                Next
              </button>
            </div>
          </>
        )}

        {activeStep === 4 && (
          <>
            <div className="flex justify-between mt-6 mb-6">
              <button
                type="button"
                onClick={handleBack}
                className="bg-[#18446b] hover:bg-[#18446b] text-white text-sm px-4 py-2
                            font-semibold uppercase rounded"
              >
                Back
              </button>
              <button
                type="submit"
                className="bg-[#18446b] hover:bg-[#18446b] text-white text-sm px-4 py-2
                            font-semibold uppercase rounded"
              >
                Finish Update
              </button>
            </div>
          </>
        )}
      </form>
    </div>
  );
}