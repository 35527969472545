import { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from '../firebase';
import { USERS_COLLECTION } from "../shared/firebaseCollections";

export function useFetchUserById({ id = null }) {

   const [data, setData] = useState({});
   const [error, setError] = useState(null);
   const [loading, setLoading] = useState(true);

   useEffect(() => {
      id && fetchFilteredData(id);
   }, [id]);

   const fetchFilteredData = async (userId) => {
      try {
         if(userId) {
            setLoading(true);
            const docRef = doc(db, USERS_COLLECTION, userId);
            const docSnap = await getDoc(docRef);
            if(docSnap.exists()){
              const user = {
                  id: docSnap.id,
                  data: docSnap.data(),
              };
              setData(user);
              setLoading(false);
              return user;
            }
         }
      } catch (error) {
        console.log(error);
        setError(error);
      } finally{
         setLoading(false);
      }
   };

   return { fetchFilteredData, data, loading, error };

}