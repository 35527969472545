import React from "react";
import SocialIcons from "./SocialIcons";
import ItemsContainer from "./ItemsContainer";
import { ICONS } from "../shared/constants";

export default function Footer() {
  return (
    <footer className="bg-gray-900 text-white">
      <div></div>
      <ItemsContainer />
      <div
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 text-center pt-2 pb-8 
      text-gray-400 text-sm "
      >
        <span>© 2023 Listing Los Cabos. Todos los derechos reservados.</span>
        <span>Términos . Política de Privacidad</span>
        <SocialIcons Icons={ICONS} />
      </div>
    </footer>
  );
}
