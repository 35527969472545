import { getAuth } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ListingItem from "../components/ListingItem";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
} from "firebase/firestore";
import { db } from "../firebase";
import { toast } from "react-toastify";

export default function Admin() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [listings, setListings] = useState(null);
  const auth = getAuth();

  // LOGOUT
  function handleLogout() {
    auth.signOut();
    navigate("/");
  }

  // FETCH USER LISTINGS
  useEffect(() => {
    async function fetchUserListings() {
      const listingRef = collection(db, "listings");
      const q = query(listingRef, orderBy("timestamp", "desc"), limit(8));
      const querySnap = await getDocs(q);

      let listings = [];
      querySnap.forEach((doc) => {
        return listings.push({
          id: doc.id,
          data: doc.data(),
        });
      });
      setListings(listings);
      setLoading(false);
    }
    fetchUserListings();
  }, []);

  // Delete or Edit Listings
  async function onDelete(listingId) {
    if (window.confirm("Are you sure you want to delete?")) {
      await deleteDoc(doc(db, "listings", listingId));
      const updatedListings = listings.filter(
        (listings) => listings.id !== listingId
      );
      setListings(updatedListings);
      toast.success("Successfully deleted the listing");
    }
  }

  function onEdit(listingId) {
    navigate(`/edit-listing/${listingId}`);
  }

  return (
    <section className="max-w-6xl mx-auto flex flex-col justify-center items-center">
      <div
        className="p-4 text-lg cursor-pointer text-blue-400 hover:text-blue-500"
        onClick={handleLogout}
      >
        Logout
      </div>
      <div className="flex flex-col items-center justify-center">
        <button
          onClick={() => navigate("/create-listing")}
          className="bg-[#18446b] hover:bg-opacity-70 px-44 py-3 text-white text-lg tracking-widest font-medium "
        >
          New Listing
        </button>
      </div>

      <div className="mx-auto px-3 mt-6">
        {!loading && listings.length > 0 && (
          <>
            <h2 className="text-2xl text-center font-semibold gap-4 mb-6 ">
              My Listings
            </h2>
            <ul className="sm:grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              {listings.map((listing) => (
                <ListingItem
                  key={listing.id}
                  id={listing.id}
                  listing={listing.data}
                  onDelete={() => onDelete(listing.id)}
                  onEdit={() => onEdit(listing.id)}
                />
              ))}
            </ul>
          </>
        )}
      </div>
    </section>
  );
}
