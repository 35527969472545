import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import success from "../assets/success.jpg";
import { getAuth } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import { USERS_COLLECTION } from "../shared/firebaseCollections";

export default function Success() {
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        // Retrieve user data from Firestore
        const userRef = doc(db, USERS_COLLECTION, user.uid); // Create a document reference
        const docSnap = await getDoc(userRef);
        if (docSnap.exists) {
          const userData = docSnap.data();
          if (!userData?.stripeSession?.subscription) {
            console.error("User exists but the subscription was not created")
            toast.error("The subscription was not created. Please, contact with an admin.");
          } else{
            toast.success("You have a subscription already activated");
          }
        } else {
          console.error("User doesn't exist")
          toast.error("The subscription was not created. Please, contact with an admin.");
        }
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePaymentSuccess = () => {
    navigate("/subscription");
  };

  return (
    <main className="flex-1 min-w-0">
      <div className="m-0 p-0">
        <div className="w-full min-h-[80vh] flex flex-col items-center justify-center">
          <div className="flex flex-col items-center justify-center mx-auto my-10">
            <img src={success} alt="success" width={220} height={220} />
            <h3 className="text-4xl font-bold text-slate-700 pt-20 md:pt-0 tracking-wider">
              Successful Payment
            </h3>
            <button
              onClick={() => handlePaymentSuccess()}
              className="w-40 text-white uppercase text-xl my-16 px-2 py-2 bg-sky-500 rounded"
            >
              Start
            </button>
          </div>
        </div>
      </div>
    </main>
  );
}
