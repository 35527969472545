export const InputNumber = ({ title, id, value, onChange, min = 0, max }) => {
  return (
    <div className="w-full">
      <p className="text-md mt-6">{title}</p>

      <div>
        <input
          onChange={onChange}
          id={id}
          value={value}
          className=" border-gray-300 focus:border-[#18446b] w-full transtion duration-150 ease-in-out text-sm"
          type="number"
          min={min}
          max={max}
        />
      </div>
    </div>
  );
};
