export const BadgeGreen = ({ text, className = '' }) => {
  return <div className={className}>
    <span className={`bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400`}>
      { text }
    </span>
  </div>
}

export const BadgeRed = ({ text, className = '' }) => {
  return <div className={className}>
    <span className={`bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400`}>
      { text }
    </span>
  </div>
}