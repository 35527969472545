import React from 'react'
import LoadingSpinner from './LoadingSpinner'

export default function Item({ links, title, loading = false }) {
  return (
    <div>
      <h1 className='mb-1 font-semibold'>{title}</h1>
      { loading && <LoadingSpinner width={10} height={10} />}
      { !loading &&
        <ul>
          {
            links.map((link, index) => (
              <li key={index}>
                <a className='text-gray-400 hover:underline duration-300 text-sm cursor-pointer leading-6' 
                  href={link.href}>{link.label}</a>
              </li>
            ))
          }
        </ul>
      }
    </div>
  )
}
