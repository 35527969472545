import { useState, useEffect } from "react";
import { collection, getDocs, query, where, or } from 'firebase/firestore'
import { db } from '../firebase';
import { COMMUNITY_COLLECTION } from "../shared/firebaseCollections";

export function useFetchCommunities({ filters = {} }) {

   const [data, setData] = useState([]);
   const [error, setError] = useState(null);
   const [loading, setLoading] = useState(false);

   useEffect(() => {
      fetchFilteredData();
   }, []);

   const fetchFilteredData = async () => {
      try {
         setLoading(true);
         let q = collection(db, COMMUNITY_COLLECTION);

         Object.keys(filters).forEach(key => {
            const value = filters[key];
            q = query(q, where(key, '==', value));
         });

         const querySnap = await getDocs(q);
         const listings = querySnap.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
         }));

         setData(listings);
      } catch (error) {
        console.log(error);
        setError(error);
      } finally{
         setLoading(false);
      }
   };

   return { fetchFilteredData, data, loading, error };

}