//import * as admin from "firebase-admin";
import { EMAIL_COLLECTION } from "../shared/firebaseCollections";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase";

export const sendEmailWithFirebase = async ({ to, subject, content }) => {
  const docRef = await addDoc(collection(db, EMAIL_COLLECTION), {
    to,
    message: {
      subject,
      html: content,
    },
  });
}