'use client';
import { useEffect, useState } from 'react';

const useWindowSizeTracker = () => {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0
  });

  useEffect(() => {
    const resizeWindow = () => {
      // console.log(window.innerWidth);
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', resizeWindow);

    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    });

    return () => window.removeEventListener('resize', resizeWindow);
  }, []);

  return { windowSize };
};

export default useWindowSizeTracker;
