import React, { useEffect, useState } from 'react';
import Modal from '../../components/Modal';

const FilterModal= ({ open = false, values, onChangeDropdown }) => {
    const [isOpen, setIsOpen] = useState(false);

    const renderOptions = ({ label, optionLabel }) => {
        return Array(7).fill().map((numb, index) => {
          return !index? <option value="">{label}</option> : <option value={index}>{index > 5? "+5" : index} {optionLabel? optionLabel : ""} {label}</option>
        })
    };

    // Fetch all listings
    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    const handleChangeDropdowns = (e) => {
        const { name, value } = e.target;
    
        // Use the name attribute of the dropdown to determine which filter to update
        const newFilters = values;
        if(name in values) {
          newFilters[name] = value;
        }
        onChangeDropdown(newFilters);
      }

    return (
        <Modal isOpen={isOpen}>
          <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4 mb-6 px-3'>
            <select 
              name='bedrooms'
              value={values['bedrooms']}
              onChange={handleChangeDropdowns}
              style={{appearance: 'none'}}
              className=' border-gray-300 focus:ring-0 text-gray-600'
            >
              {renderOptions({ label: "Bedrooms" })}
            </select>
            
            <select 
              name='bathrooms'
              value={values['bathrooms']}
              onChange={handleChangeDropdowns}
              className='border-gray-300 focus:ring-0 text-gray-600'
            >
              {renderOptions({ label: "Bathrooms" })}
            </select>
            
            <select 
              name='garage'
              value={values['garage']}
              onChange={handleChangeDropdowns}
              className=' border-gray-300 focus:ring-0 text-gray-600'
            >
              {renderOptions({ label: "Garages" })}
            </select>
            
            <select 
              name='antiquity'
              value={values['antiquity']}
              onChange={handleChangeDropdowns}
              className=' border-gray-300 focus:ring-0 text-gray-600'
            >
              {renderOptions({ label: "Antiquity", optionLabel: "years of " })}
            </select>
        
            <select 
              name='minPrice'
              value={values['minPrice']}
              onChange={handleChangeDropdowns}
              className=' border-gray-300 focus:ring-0 text-gray-600'
            >
              <option value="">Min $</option>
              <option value="50000">$50,000</option>
              <option value="75000">$75,000</option>
              <option value="100000">$100,000</option>
              <option value="125000">$125,000</option>
              <option value="150000">$150000</option>
              <option value="175000">$175,000</option>
              <option value="200000">$200,000</option>
              <option value="225000">$225,000</option>
              <option value="250000">$250,000</option>
            </select>

            <select 
              name='maxPrice'
              value={values['maxPrice']}
              onChange={handleChangeDropdowns}
              className=' border-gray-300 focus:ring-0 text-gray-600'
            >
              <option value="">Max $</option>
              <option value="50000">$50,000</option>
              <option value="75000">$75,000</option>
              <option value="100000">$100,000</option>
              <option value="125000">$125,000</option>
              <option value="150000">$150000</option>
              <option value="175000">$175,000</option>
              <option value="200000">$200,000</option>
              <option value="225000">$225,000</option>
              <option value="250000">$250,000</option>
            </select>
          </div>
        </Modal>
    )
}

export default FilterModal;