import React, { createRef, useState } from "react";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import { FlexInputText } from "./Flex/FlexInputText";

const _ = require("lodash");

//TODO(): refactor to input param
const fields = [
  {
    id: "firstName",
    label: "First Name", 
    type: "text",
    placeholder: "First name", 
    rootClass: "sm:w-1/2",
    required: true,
  },
  {
    id: "lastName",
    label: "Last Name", 
    type: "text",
    placeholder: "Last name", 
    rootClass: "sm:w-1/2",
    required: true,
  },
  {
    id: "email",
    label: "Email", 
    type: "email",
    placeholder: "Email",
    required: true,
  },
  {
    id: "phone",
    label: "Phone", 
    type: "text",
    placeholder: "+1 (555) 444-0000",
    required: true,
  },
  {
    id: "comment",
    label: "Comment", 
    type: "textarea",
    rows: "4",
    placeholder: "Type your message",
    required: true,
  },
];

const btnSendTxt = "Send Inquiry";

const initState = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  comment: "",
};
export const GetInTouchForm = ({ onSubmit = () => {} }) => {
  const [ form, setForm ] = useState(initState);
  const [ captcha, setCaptcha ] = useState({});
  const [ captchaError, setCaptchaError ] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: initState
  });
  const recaptchaRef = createRef();

  const sendInquiry = (data) => {

    if(captcha && !_.isEmpty(captcha)) {
      onSubmit(data);
      resetForm();
      recaptchaRef.current?.reset();
      setCaptchaError(false);
      setCaptcha(null);
    } else {
      setCaptchaError(true);
    }
  };

  const resetForm = () => {
    setForm(initState);
  }

  const onCaptchaChange = (value) => {
    setCaptcha(value);
  }

  return (
    <section className="relative z-10 overflow-hidden bg-white py-8 md:py-10 lg:py-8 dark:bg-dark">
      <form onSubmit={handleSubmit(sendInquiry)}>
      <div className="container">
        <div className="mx-auto w-full max-w-[540px]">
          <div className="-mx-4 flex flex-wrap">
            {fields.map(field => {
              return (
                <FlexInputText 
                  register={register}
                  error={errors[field.id] ?? null}
                  key={field.id}
                  id={field.id} 
                  label={field.label}
                  placeholder={field.placeholder}
                  required={field.required}
                  type={field.type}
                  rows={field.rows}
                  value={form[field.id]}
                  onChange={(e) => setForm({ ...form, [field.id]: e.target.value })}
                  rootClass={field.rootClass}
                />
              );
            })}
            <div className="w-full flex flex-col px-4 pb-4 justify-center text-center items-center">
              <ReCAPTCHA 
                ref={recaptchaRef} 
                onChange={onCaptchaChange}
                sitekey={process.env.REACT_APP_GOOGLECAPTCHA_SITE_KEY} />
                { captchaError && <div className="w-full text-sm text-red-500">Complete captcha</div>}
            </div>
            <div className="flex w-full px-4 justify-center">
              <button type="submit" className="flex h-10 w-60 items-center justify-center bg-dark border border-black px-5 py-3 font-medium text-slate-600 duration-200 hover:bg-slate-200/90 dark:bg-white dark:text-black dark:hover:bg-white/90">
                { btnSendTxt }
              </button>
            </div>
          </div>
        </div>
      </div>
      </form>
    </section>
  );
}