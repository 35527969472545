export const Select = ({ id, title, value, options, onChange }) => {
  return (
    <div className="w-full">
      {title && <p className="text-md mt-6">{title}</p>}

      <div>
        <select
          onChange={onChange}
          id={id}
          className="border-gray-300 focus:border-[#18446b] text-sm w-full
        transition duration-150 ease-in-out"
        >
          {options.map((option, index) => (
            <option
              key={index}
              value={option.id}
              selected={option.id === value ? true : false}
            >
              {option.customLabel? option.customLabel : option.value }
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
